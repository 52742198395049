import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, Divider, List, ListItemText } from "@mui/material";
//
import { StyledNavItem } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection() {
  return (
    <Box>
      <List disablePadding sx={{ p: 1, height: "57.22px" }}>
        <NavItem />
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem() {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          src="/assets/images/uoe4289/logo.svg"
          alt="logo"
          sx={{ height: "88px" }}
        />
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box>
        {/* <StyledNavItem
                    component={RouterLink}
                    to="/admin/dashboard"
                    sx={{
                        fontSize: "20px",
                        color: "black",
                        '&.active': {
                            color: 'text.primary',
                            bgcolor: 'action.selected',
                            fontWeight: 600,
                        },
                    }}
                >
                    <ListItemText disableTypography primary="Dashboard" sx={{ display: "flex", justifyContent: "center", }} />
                </StyledNavItem> */}
        <StyledNavItem
          component={RouterLink}
          to="/admin"
          sx={{
            fontSize: "20px",
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText
            disableTypography
            primary="งานทั้งหมด"
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </StyledNavItem>
        <StyledNavItem
          component={RouterLink}
          to="/"
          sx={{
            fontSize: "20px",
            marginTop: "10px",
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText
            disableTypography
            primary="หน้าหลัก"
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </StyledNavItem>
      </Box>
    </>
  );
}

const darkTheme = (mode) => ({
  palette: {
    mode,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        mode,
        ...(mode === "light"
          ? {
              // palette values for light mode
              body: {
                backgroundColor: "#f3f3f9",
                ".thead": {
                  color: "white",
                  backgroundColor: "#cacaca",
                },
                ".section-white": {
                  backgroundColor: "#fff",
                },
                ".nav-bar": {
                  backgroundColor: "#fff",
                },
                ".toolheadtable": {
                  backgroundColor: "#f3f3f9",
                },
                ".autocompletebg": {
                  backgroundColor: "#fff",
                },
                ".buttontheme": {
                  color: "#000",
                },
                ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                  color: "#af0104",
                },
                ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
                  color: "#af0104",
                },
                ".textcolor": {
                  color: "#000",
                },
                ".tablerow": {
                  borderBottom: "2px solid",
                },
                ".tableprivacy": {
                  borderStyle: "solid",
                  borderBottom: "solid",
                },
                ".nav-text": {
                  color: "black",
                },
              },
            }
          : {
              // palette values for dark mode
              body: {
                ".thead": {
                  color: "white",
                  backgroundColor: "#080808",
                },
                ".section-light": {
                  backgroundColor: "#2A2F34",
                },
                ".section-white": {
                  backgroundColor: "#121212",
                },
                ".nav-bar": {
                  backgroundColor: "#2A2F34",
                },
                ".toolheadtable": {
                  backgroundColor: "#121212",
                },
                ".autocompletebg": {
                  backgroundColor: "#121212",
                },
                ".buttontheme": {
                  color: "#fff",
                },
                ".css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                  color: "#af0104",
                },
                ".css-m8a1uf-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
                  color: "#af0104",
                },
                ".textcolor": {
                  color: "#fff",
                },
                ".tablerow": {
                  borderBottom: "2px solid",
                },
                ".tableprivacy": {
                  borderStyle: "solid",
                  borderBottom: "solid",
                },
                ".nav-text": {
                  color: "white",
                },
              },
            }),
      },
    },
  },
});

export default darkTheme;

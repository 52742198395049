import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { Trans } from 'react-i18next'

export default function AboutRegister() {
    return (
        <Box component="section" className='section-white'>
            <Stack mb={4}>
                <Container>
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Typography variant='h2' fontSize="34px" fontWeight={600} textAlign="center"><Trans i18nKey="registertitleabout" /></Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Typography variant='body1' fontSize="16px" textAlign="center"><Trans i18nKey="registerdetailabout" /></Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Button color='error' variant="contained" size='large'><Trans i18nKey="registerbuttonabout" /></Button>
                    </Box>
                </Container>
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                    <Box component="img" src='/assets/images/uoe4289/ab01.svg' alt='sdsad' width="100%" height="100%" />
                </Box>
            </Stack>
        </Box>
    )
}

import { useState } from "react";
// @mui

import {
    Box,
    Divider,
    Typography,
    MenuItem,
    Avatar,
    IconButton,
    Popover,
} from "@mui/material";

export default function AccountPopover() {

    const user = JSON.parse(localStorage.getItem('user'))
    const [open, setOpen] = useState(null);
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(null);
    };
    const handleLogout = () => {
        localStorage.removeItem("user");
        window.location.href = "/";
    };
    return (
        <>
            <IconButton onClick={handleOpen} sx={{ height: "40px", width: "40px" }}>
                <Avatar sx={{ bgcolor: "red", height: "36px", width: "36px" }} alt={user.username} />
            </IconButton>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        "& .MuiMenuItem-root": {
                            typography: "body2",
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        {user.username}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    )
}

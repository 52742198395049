import React from 'react'
import { Button, Container, Stack, Typography, Box } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import { Trans } from 'react-i18next';

export default function Ads2() {
    return (
        <Box component="section" className='section-white'>
            <Stack>
                <Container>
                    <Stack my={4}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={8}>
                                <Typography variant='h2' fontSize="34px" fontWeight={600} color="#c60c0c"><Trans i18nKey="ads2titlehr" /></Typography>
                                <Typography variant='body1' fontSize="16px"><Trans i18nKey="ads2detailhr" /></Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={4}>
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                    <Button variant='contained' color='error' size='large'><Trans i18nKey="ads2buttonhr" /></Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Stack>
        </Box>
    )
}

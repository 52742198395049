import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';

import Header from './navbar';
import Sidebar from './sidebar'
import Footer from './footer';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
    },
}));

export default function Admin(props) {

    const { colorMode, ColorModeContext } = props
    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledRoot>
                <Header openNav={open} onOpenNav={() => setOpen(true)} onCloseNav={() => setOpen(false)} colorMode={colorMode} ColorModeContext={ColorModeContext} />

                <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />

                <Main>
                    <Outlet />
                </Main>

            </StyledRoot>
            <Footer />
        </>
    )
}

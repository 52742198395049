import {
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { WorkHead, WorkRow, WorkTool } from "./tableworkAdmin";
import { useMediaQuery } from "react-responsive";
import ViewWork from "./ViewWork";
import EditWork from "./EditWork";
import AddWork from "./AddWork";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import { applySortFilter, getComparator } from "../../utils/table_help";

export default function Work() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(`date`);

  const [filterName, setFilterName] = useState("");

  const [searchPositon, setSearchPositon] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 721px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  let TABLE_HEAD = [];
  if (isMobile) {
    TABLE_HEAD = [
      { id: "position", label: "ตำแหน่ง" },
      { id: "companyName", label: "บริษัท" },
      { id: "action", label: "Action" },
    ];
  } else if (isDesktopOrLaptop) {
    TABLE_HEAD = [
      { id: "id", label: "ลำดับ" },
      { id: "position", label: "ตำแหน่ง" },
      { id: "companyName", label: "บริษัท" },
      { id: "province", label: "จังหวัด" },
      { id: "rate_em", label: "รับสมัครอัตรา", numeric: true },
      { id: "date", label: "วันที่ลง" },
      { id: "action", label: "Action" },
    ];
  }

  const [workData, setWorkData] = useState([]);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API}/data`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        const dData = resp
          .filter(
            (days) =>
              moment(days.expiredate, "MM/DD/YYYY : HH:mm:ss").diff(
                moment(),
                "days"
              ) === 0
          )
          .map((item) => item.id);
        // console.log(dData)
        for (var i = 0; i < dData.length; i++) {
          axios
            .delete(`${process.env.REACT_APP_API}/data/` + dData[i])
            .then((res) => res)
            .catch((err) => err);
        }
        return setWorkData(resp);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = workData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleFilterByName = (e) => {
    setPage(0);
    setFilterName(e.target.value);
  };

  const handleProvince = (e, value) => {
    setSearchProvince(e.target.value);
    // setSearchProvince(value);
  };

  const handlePositon = (e, value) => {
    setSearchPositon(e.target.value);
    // setSearchPositon(value);
  };

  const handleCompany = (e) => {
    setSearchCompany(e.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - workData.length) : 0;

  const filteredWorks = applySortFilter(
    workData,
    getComparator(order, orderBy),
    filterName,
    searchPositon,
    searchCompany,
    searchProvince
  );

  const isNotFound = !filteredWorks.length && !!filterName;

  const [pageNow, setPageNow] = useState("default");
  const [pageId, setPageId] = useState(0);
  const handlePageNow = (e, ids) => {
    if (e.target.id === "default") {
      setPageNow(e.target.id);
      setPageId(0);
    } else if (e.target.id === "deletework") {
      swal({
        title: "Are you sure?",
        text: "เมื่อลบข้อมูลแล้ว คุณจะไม่สามารถกู้ข้อมูลนั้น คุณยืนยันจะลบต่อหรือไม่",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(`${process.env.REACT_APP_API}/data/${ids}`)
            .then((res) => {
              swal("Success", "คุณลบข้อมูลได้สำเร็จ", "success").then(
                (resp) => {
                  window.location.href = "workall";
                }
              );
            })
            .catch((err) => {
              swal("Error", "เกิดปัญหาเรื่อง Demo Database", "error");
            });
        } else {
          swal("ยกเลิกการลบข้อมูล!");
        }
      });
    } else {
      setPageNow(e.target.id);
      setPageId(ids);
    }
  };

  return (
    <React.Fragment>
      {pageNow === "default" && pageId === 0 ? (
        <Stack mx={2} my={2}>
          <Card>
            <WorkTool
              work={workData}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              searchPositon={searchPositon}
              onPosition={handlePositon}
              searchCompany={searchCompany}
              onCompany={handleCompany}
              searchProvince={searchProvince}
              onProvince={handleProvince}
              handlePageNow={handlePageNow}
            />
            <TableContainer sx={{ minWidth: "auto" }}>
              <Table>
                <WorkHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={workData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredWorks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <WorkRow
                          row={row}
                          key={row.id}
                          index={index}
                          handlePageNow={handlePageNow}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          ไม่มี
                        </Typography>

                        <Typography variant="body2">ข้อมูลว่างเปล่า</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography variant="h6" paragraph>
                          ไม่เจอ
                        </Typography>
                        <Typography variant="body2">
                          ไม่เจอข้อมูลที่ &nbsp;
                          <strong>
                            &quot;คุณกำลังค้นหาตำแหน่งงานที่ต้องการ&quot;
                          </strong>
                          .
                          <br />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={workData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Stack>
      ) : pageNow === "addwork" ? (
        <>
          <AddWork />
        </>
      ) : pageNow === "viewwork" ? (
        <>
          {workData
            .filter((value) => value.id === pageId)
            .map((row) => {
              return (
                <ViewWork row={row} key={row} handlePageNow={handlePageNow} />
              );
            })}
        </>
      ) : pageNow === "editwork" ? (
        <>
          {workData
            .filter((value) => value.id === pageId)
            .map((row) => {
              return (
                <EditWork row={row} key={row} handlePageNow={handlePageNow} />
              );
            })}
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

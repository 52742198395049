import { Box, Typography, Container } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react'

function Copyright() {
    return (
        <Box>
            <Typography variant="body2" color="white">
                {'© Copyright '}
                - uoe4289.com | หางาน | หาคน | งานทรัพยากรบุคคล
            </Typography>
        </Box>
    );
}

const defaultTheme = createTheme();

export default function Footer() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                className='nav'
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: "#222222"
                }}>
                <Container maxWidth="sm">
                    <Box sx={{ display: "flex", justifyContent: "start", }}>
                        <Copyright />
                    </Box>
                </Container>
            </Box >
        </ThemeProvider >
    )
}

import { Card, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { WorkHead, WorkRowSmall } from '../tablework'

export default function Work(props) {

    const { workData, row, handlePageNow } = props

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState(`id`);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query, state) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query || state) {
            const search = array.filter((val) => {
                if (val?.status.toLowerCase().indexOf(state.toLowerCase()) !== -1) {
                    if (
                        val?.firstnameth?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    ) {
                        return val;
                    } else if (
                        val?.lastnameth?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    ) {
                        return val;
                    } else if (
                        val?.nickname?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    ) {
                        return val;
                    } else if (
                        val?.cpn_n?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    ) {
                        return val;
                    }
                }
                return val;
            });
            return search;
        }
        return stabilizedThis.map((el) => el[0]);
    }


    let TABLE_HEAD = [
        { id: "positionW", label: "ตำแหน่ง" },
        { id: "companyName", label: "บริษัท" },
        { id: "rate_em", label: "รับสมัครอัตรา", numeric: true },
    ];


    const handleRequestSort = (e, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (e) => {
        if (e.target.checked) {
            const newSelecteds = workData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value, 10));
    };

    const samework = workData.filter((value) => value.companyName === row[0].companyName && value.id !== row[0].id)

    const emptyRows = samework.length === 0;

    const filteredWorks = applySortFilter(
        workData,
        getComparator(order, orderBy),
    );

    return (
        <React.Fragment>
            <Stack mx={2} my={3}>
                <Card>
                    <Stack direction="row" alignItems="center" justifyContent="center" my={3} mx={2}>
                        <Typography variant="h4" gutterBottom sx={{ fontSize: "24px" }}>
                            ตำแหน่งงานอื่นๆ ของ {row[0].companyName}
                        </Typography>
                    </Stack>
                    <TableContainer sx={{ minWidth: "auto" }}>
                        <Table>
                            <WorkHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={samework.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredWorks.filter((value) => value.companyName === row[0].companyName && value.id !== row[0].id).map((rows) => {
                                    return (
                                        <WorkRowSmall row={rows} key={rows.id} handlePageNow={handlePageNow} />
                                    )
                                })}
                                {samework.length === 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                            <Typography variant="h6" paragraph>
                                                ไม่มี
                                            </Typography>

                                            <Typography variant="body2">
                                                ไม่มีข้อมูลของ &nbsp;
                                                <strong>&quot;บริษัทที่เปิดรับสมัครพนักงาน&quot;</strong>.
                                                <br />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={samework.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Stack>
        </React.Fragment>
    )
}
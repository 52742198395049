import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function Why() {
    return (
        <Box component="section" className='section-white' sx={{ backgroundColor: "white" }}>
            <Container>
                <Stack mx={3}>
                    <Stack mx={2} my={10}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6}>
                                <Typography variant='h1' color="#c60c0c" fontSize="28px" fontWeight={600}><Trans i18nKey="whytitlehome" /></Typography>
                                <Typography variant='body1' color="#898989"><Trans i18nKey="whysubhome" /></Typography>
                                <Typography variant='body1'><Trans i18nKey="whydetailhome" /></Typography>
                                <Box mt={2}>
                                    <Button color='error' variant="contained" startIcon={<Iconify icon="material-symbols:search" />} ><Trans i18nKey="whybuttonhome" /></Button>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Box>
                                    <Box component="img" src='/assets/images/uoe4289/UOE.svg' alt='why pic' width="100%" height="100%" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

import React from "react";
// import { NavLink as RouterLink, Link } from "react-router-dom";
// import PropTypes from 'prop-types';
// @mui
import { styled } from "@mui/material/styles";
import {
    Box,
    Container,
    Toolbar,
    Typography,
} from "@mui/material";
import Iconify from "../../../comonents/iconify";

const HEADER_MOBILE = 30;
const HEADER_DESKTOP = 30;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    backgroundColor: '#ae0003',
    [theme.breakpoints.up("lg")]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

export default function SideBarSeactionRed() {

    return (
        <StyledToolbar>
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Box>
                        <Typography sx={{ fontSize: 11 }}>Call Center : +66 080-193-5295, 080-193-9635 | Line@ : @UOE4289</Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                        <Box component="a" href="https://www.facebook.com/profile.php?id=100089135664630" ><Iconify icon='ri:facebook-fill' color='white' /></Box>
                        <Box component="a" ><Iconify icon='ri:instagram-fill' color='white' /></Box>
                        <Box component="a" ><Iconify icon='ri:youtube-fill' color='white' /></Box>
                    </Box>
                </Box>
            </Container>
        </StyledToolbar>
    )
}

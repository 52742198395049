export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query, positionW, companyName, province) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query || positionW || companyName || province) {
        const search = array.filter((val) => {
            if (val?.province.toLowerCase().indexOf(province.toLowerCase()) !== -1) {
                if (val?.positionW.toLowerCase().indexOf(positionW.toLowerCase()) !== -1) {
                    if (val?.companyName.toLowerCase().indexOf(companyName.toLowerCase()) !== -1) {
                        if (
                            val?.companyName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                        ) {
                            return val;
                        } else if (
                            val?.positionW?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                        ) {
                            return val;
                        } else if (
                            val?.province?.toLowerCase().indexOf(query.toLowerCase()) !== -1
                        ) {
                            return val;
                        }
                        return false
                    }
                    return false
                }
                return false
            }
            return false
        });
        return search;
    }
    return stabilizedThis.map((el) => el[0]);
}
import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Card, CardContent, CardHeader, Container, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function Package() {

    const packaging = [
        {
            id: 1,
            title: "packagefreetitlehr",
            subheader: "packagefreesubhr",
            detail1: "packagefreedetailhr",
            detail2: "packagefreeprojecthr",
            detail3: "packagefreesupporthr",
            button: "packagebuttonhr"
        },
        {
            id: 2,
            title: "packagepretitlehr",
            subheader: "packagepresubhr",
            detail1: "packagepredetailhr",
            detail2: "packagepreprojecthr",
            detail3: "packagepresupporthr",
            button: "packagebuttonhr"
        },
        {
            id: 3,
            title: "packagebusinesstitlehr",
            subheader: "packagebusinesssubhr",
            detail1: "packagebusinessdetailhr",
            detail2: "packagebusinessprojecthr",
            detail3: "packagebusinesssupporthr",
            button: "packagebuttonhr"
        },
    ]
    return (
        <Container>
            <Stack mb={3}>
                <Stack my={6}>
                    <Box sx={{ display: "flex", justifyContent: "center", marginY: 6 }}>
                        <Typography sx={{ fontSize: 34, fontWeight: 600, textAlign: "center" }}><Trans i18nKey="packagetitlehr" /></Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {packaging.map((item) => {
                            return (
                                <Grid xs={12} sm={12} md={4} key={item.id}>
                                    <Box>
                                        <Card>
                                            <CardHeader sx={{ textAlign: "center", backgroundColor: "#c60c0c", color: "white !important" }} title={<Trans i18nKey={item.title} />} subheader={<Trans i18nKey={item.subheader} />} />
                                            <CardContent>
                                                <Typography variant='body1' sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                                    <Trans i18nKey={item.detail1} />
                                                </Typography>
                                                <Typography variant='body1' my={3} color="#c60c0c" sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Trans i18nKey={item.detail2} />
                                                </Typography>
                                                <Typography variant='body1' color="#c60c0c" sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Trans i18nKey={item.detail3} />
                                                </Typography>
                                                <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Button color='error' variant="contained" size='large'><Trans i18nKey={item.button} /></Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}

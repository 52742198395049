import React from 'react'
import {
    Box,
    Typography,
} from "@mui/material";

export default function Part10() {
    return (
        <React.Fragment>
            <Box my={1}>
                <Typography className="text-bold">นโยบายคุกกี้ (Cookie Policy) และการจัดเก็บข้อมูลบนเครื่อง (Local Storage) ของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</Typography>
                <Typography className="text-bold">คุกกี้และการจัดเก็บข้อมูลบนเครื่องคืออะไร</Typography>
                <Typography className="text-normal">&nbsp;&nbsp;หากคุณเยี่ยมชมเว็บไซต์ของเรา ข้อมูลจะถูกจัดเก็บไว้ที่เว็บบราวเซอร์หรือบันทึกไว้บนเครื่องคอมพิวเตอร์หรืออุปกรณ์เคลื่อนที่ เช่น สมาร์ทโฟน (Smartphone) แท็บเล็ต (Tablet) เพื่อช่วยให้เรารวบรวมและจดจำข้อมูลการเยี่ยมชมเว็บไซต์ของคุณรวมถึงช่วยปรับปรุงการใช้งานออนไลน์ของคุณให้ดีขึ้น ซึ่งการจัดเก็บข้อมูลนี้จะไม่เป็นอันตรายต่อเครื่องคอมพิวเตอร์หรือความปลอดภัยของข้อมูลของคุณ</Typography>
                <Typography className="text-normal">เราขอแจ้งให้ทราบว่าคุกกี้บางประเภทในเว็บไซต์นี้อาจถูกรวบรวมและวิเคราะห์โดยผู้ให้บริการบุคคลที่สาม อาทิ ผู้ประกอบการโฆษณาเพื่อการตลาดในเครือข่ายและผู้ให้บริการเว็บไซต์ภายนอกอื่น ๆ เช่น ผู้ให้บริการวิเคราะห์การเข้าเยี่ยมชมเว็บไซต์ เป็นต้น โดยคุณควรศึกษานโยบายการใช้คุกกี้และนโยบายความเป็นส่วนตัวในเว็บไซต์ของบุคคลเหล่านั้นเพื่อให้เข้าใจถึงวิธีการที่บุคคลนั้นอาจนำข้อมูลของคุณไปใช้</Typography>
            </Box>
            <Box my={1}>
                <Typography className="text-bold">วัตถุประสงค์ในการใช้คุกกี้และการจัดเก็บข้อมูลบนเครื่อง</Typography>
                <Typography className="text-normal">เราใช้คุกกี้และการจัดเก็บข้อมูลบนเครื่องเพื่อจุดประสงค์ดังต่อไปนี้</Typography>
                <ul>
                    <li className='text-normal'>เพื่อรวบรวมสถิติที่ไม่มีการระบุตัวตน ซึ่งจะช่วยให้เราสามารถเข้าใจว่าพฤติกรรมผู้ใช้ในการใช้งานเว็บไซต์ของเรา และช่วยในการปรับปรุงเนื้อหาและโครงสร้างของเว็บไซต์ของเราให้มีประสิทธิภาพ ตอบโจทย์ผู้ใช้งานอย่างต่อเนื่อง โดยสถิตินี้อาจถูกรวบรวมและวิเคราะห์โดยผู้ให้บริการรายอื่น เช่น Google Tag Manager, Google Analytics, Facebook Pixel, Facebook Analytics, Huawei Analytics เป็นต้น</li>
                    <li className='text-normal'>เพื่อวิเคราะห์และนำเสนอโฆษณาที่เหมาะสมกับความต้องการของคุณ โดย Google Ad Manager</li>
                    <li className='text-normal'>เพื่อเชื่อมต่อกับเซสชั่น (Session) ของผู้ใช้ในการเข้าสู่ระบบ</li>
                    <li className='text-normal'>เพื่อจดจำการใช้งานหรือข้อมูลบางส่วนที่ผู้ใช้เคยระบุไว้บนระบบ</li>
                    <li className='text-normal'>เพื่ออำนวยความสะดวกในการใช้งานของผู้ใช้ เช่น การจำข้อมูลการกรอกประวัติแบบย่อและอัปโหลดไฟล์ที่ผู้ใช้กรอกล่าสุดทำให้ผู้ใช้ไม่ต้องกรอกใหม่ในครั้งต่อไป เป็นต้น</li>
                </ul>
            </Box>
            <Box my={1}>
                <Typography className="text-bold">การเปลี่ยนหรือการลบการตั้งค่าคุกกี้ของคุณ</Typography>
                <Typography className="text-normal">&nbsp;&nbsp;โดยส่วนมากเว็บบราวเซอร์ (Web Browser) จะถูกตั้งค่ายอมรับการใช้งานคุกกี้โดยอัตโนมัติ อย่างไรก็ตาม คุณสามารถปิดหรือควบคุมการใช้งานคุกกี้ด้วยการตั้งค่าเว็บบราวเซอร์ หากคุณไม่ต้องการให้คุกกี้ทำการรวบรวมข้อมูลบนเว็บไซต์ของเรา คุณสามารถทำได้โดยการเข้าไปที่เมนูการตั้งค่าในแถบเครื่องมือบนเว็บบราวเซอร์ที่คุณใช้งานเพื่อเปลี่ยนแปลงการตั้งค่าที่ยอมรับไว้ โดยการดำเนินการถอนคำยินยอมดังกล่าวจะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลที่คุณได้ให้ความยินยอมไปแล้วโดยชอบด้วยกฎหมาย</Typography>
                <Typography className="text-normal">ทั้งนี้ ทางเราขอเรียนให้ทราบว่า การปิดใช้งานคุกกี้ของคุณ จะทำให้คุณไม่สามารถใช้งานหรือเข้าถึงฟังก์ชันบางอย่างหรือเนื้อหาบางส่วนที่เว็บไซต์ของเรานำเสนอได้ และอาจจะทำให้คุณได้รับความสะดวกในการให้บริการน้อยลง อย่างไรก็ตาม คุณสามารถลบข้อมูลที่ถูกจัดเก็บไว้บนอุปกรณ์ได้โดยการลบประวัติการใช้เว็บบราวเซอร์ของคุณ</Typography>
            </Box>
            <Box my={1}>
                <Typography className="text-bold">วัตถุประสงค์ในการใช้คุกกี้และการจัดเก็บข้อมูลบนเครื่อง</Typography>
                <Typography className="text-normal">&nbsp;&nbsp;คุณสามารถติดต่อเราสำหรับข้อมูลเพิ่มเติมเกี่ยวกับถ้อยแถลงเกี่ยวกับนโยบายฉบับนี้ สิทธิ์ของคุณ หรือการประมวลผลข้อมูลของคุณที่เกี่ยวข้องอื่น ๆ ได้ที่</Typography>
                <Typography className="text-normal">บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด (สำนักงานใหญ่)</Typography>
                <Typography className="text-normal">9 อาคารจี ทาวเวอร์ แกรนด์ พระราม 9 ชั้น 30 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวางกรุงเทพมหานคร 10310</Typography>
                <Typography className="text-normal">โทรศัพท์ 02-096-2236 กด 2</Typography>
                <Typography className="text-normal">อีเมล mgr.hr@uoe4289.com</Typography>
            </Box>
        </React.Fragment>
    )
}

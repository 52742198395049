import React from 'react'
import { Box, Stack, Typography } from '@mui/material';

export default function ResumeTitle() {
    return (
        <Stack>
            <Box sx={{ position: "relative", width: "100%", clear: "both", float: "left", overflow: "hidden" }}>
                <Stack mx={2} mb={2}>
                    <Box sx={{ top: "auto", opacity: 1, bottom: 0, left: 0, width: "100%", zIndex: 0 }}>
                        <Box component="div" className="overlay" sx={{ opacity: 0.8, backgroundColor: "#000000" }}></Box>
                        <Box sx={{ backgroundImage: `url('/assets/images/uoe4289/slider_header.svg')`, backgroundRepeat: "repeat-x", backgroundPosition: "center center", position: "absolute", width: "100%", height: "100%", bottom: 0, left: 0 }}></Box>
                    </Box>
                    <Box sx={{ position: "relative", width: "100%" }}>
                        <Box className='container' my={4}>
                            <Box sx={{ border: "none", marginLeft: "auto", paddingLeft: 0, marginRight: 0, zoom: 1, }} color="white">
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='h1' fontWeight={600} fontSize="34px" textAlign="center">APPLICATION FORM REGISTER RESUME</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='body1' fontSize="16px" textAlign="center">UOE4289 เราคือผู้ให้บริการ และตอบรับความต้องการเรื่องบุคลากรภายในองค์กร ให้คุณและองค์กรของคุณ มีทรัพยากรบุคคลที่มีคุณภาพคุ้มค่าตอบโจทย์ได้ดีที่สุด</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}

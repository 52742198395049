import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Box, Container, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import Aos from 'aos';
import $ from 'jquery'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function HomeTitle() {
    const SwiperImage = [
        {
            id: 1,
            url: `url('/assets/images/uoe4289/002-1.svg')`,
            trtitle: 'trtitle1',
            trsub: 'trsub1',
        },
        {
            id: 2,
            url: `url('/assets/images/uoe4289/001-1.svg')`,
            trtitle: 'trtitle2',
            trsub: 'trsub2',
        },
        {
            id: 3,
            url: `url('/assets/images/uoe4289/004-1.svg')`,
            trtitle: 'trtitle3',
            trsub: 'trsub3',
        },
    ]

    const handleSlide = () => {
        $('.card').removeClass('aos-init').removeClass('aos-animate')
    }

    const handleStart = () => {
        $('.card').removeClass('aos-init').removeClass('aos-animate')
    }

    const handleEnd = () => {
        Aos.init()
    }

    return (
        <>
            <Box>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    allowTouchMove={false}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    pagination={{ clickable: true }}
                    modules={[Autoplay, Navigation, Pagination]}
                    onSlideChange={handleSlide}
                    onSlideChangeTransitionStart={handleStart}
                    onSlideChangeTransitionEnd={handleEnd}
                    className="mySwiper"
                >
                    {SwiperImage.map((item) => {
                        return (
                            <SwiperSlide className='swiper' key={item.id}>
                                <Box sx={{ top: "auto", opacity: 1, bottom: 0, left: 0, zIndex: 0, height: { xs: "300px", lg: "600px" } }}>
                                    <Box component="div" className="overlay" sx={{ opacity: 0.8, backgroundColor: "#000000" }}></Box>
                                    <Box sx={{ backgroundImage: `${item.url}`, backgroundRepeat: "repeat-x", backgroundPosition: "center center", position: "absolute", width: "100%", height: "100%", bottom: 0, left: 0 }}></Box>
                                </Box>
                                <Box className='card' id={item.id} sx={{ position: "relative", width: "100%" }} data-aos="zoom-in">
                                    <Box className='container' my={10} mx={{ xs: 5 }}>
                                        <Container>
                                            <Box sx={{ border: "none", marginLeft: "auto", paddingLeft: 0, marginRight: 0, zoom: 1, }} color="white">
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='h1' fontWeight={600} fontSize={{ xs: "20px", lg: "48px" }}>
                                                        <Trans i18nKey={item.trtitle} />
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' fontSize={{ xs: "16px", lg: "34px" }}>
                                                        <Trans i18nKey={item.trsub} />
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Box >
        </>
    )
}

import { Box, Button, Menu, MenuItem } from '@mui/material'
import React from 'react'
import i18next from 'i18next';

export default function Language() {

    const [open, setOpen] = React.useState(null);
    const handleFlagLanguage = (e) => {
        setOpen(e.currentTarget)
    }
    const handleClose = () => {
        setOpen(null);
    };

    const lngs = {
        th: { nativeName: "ภาษาไทย" },
        en: { nativeName: "English" }
    }

    const [flag, setFlag] = React.useState(i18next.language === 'en-US' ? 'en' : i18next.language);

    const handleChange = (e) => {
        // console.log(e.target.id)
        setFlag(e.target.id);
        i18next.changeLanguage(e.target.id)
        setOpen(null);
    };


    return (
        <Box mx={2}>
            <Button onClick={handleFlagLanguage} size="small">
                <Box component="img" alt='flag' src={`/assets/flag/${flag}.svg`} width="35px" />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {Object.keys(lngs).map((lng) => {
                    return (
                        <MenuItem key={lng} id={lng} value={lng} onClick={handleChange} selected={lng === flag}><Box component="img" id={lng} alt='flag' src={`/assets/flag/${lng}.svg`} sx={{ width: "20px", marginRight: 2 }} />{lngs[lng].nativeName}</MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}

import React from 'react'
import { Helmet } from "react-helmet-async";
import { AboutRegister, AboutTitle, EveryDays, Why } from '../comonents/about';

export default function AboutPage() {
    return (
        <>
            <Helmet>
                <title> เกี่ยวกับเรา | UOE4289 </title>
            </Helmet>

            <AboutTitle />
            <AboutRegister />
            <Why />
            <EveryDays />
        </>
    )
}

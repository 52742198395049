import React from "react";
import { NavLink as RouterLink, Link } from "react-router-dom";
// import PropTypes from 'prop-types';
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, Toolbar, IconButton, ListItemText } from "@mui/material";
import { StyledNavItem } from "./styles";
import { Trans } from "react-i18next";

// components
import Iconify from "../../../comonents/iconify";
import AccountPopover from "./AccountPopover";
import Language from "./Language";
import Theme from "./Theme";

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 0),
  },
}));

export default function SideBarSeaction(props) {
  const { state, setState, colorMode } = props;

  const toggleDrawer = (left, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, left: open });
  };

  return (
    <StyledToolbar>
      <IconButton
        onClick={toggleDrawer(state.left, true)}
        sx={{
          mr: 1,
          color: "#c60c0c",
          display: { lg: "none" },
        }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>

      <Link to="/">
        <Box
          component="img"
          src="/assets/images/uoe4289/logo.svg"
          alt="logo"
          sx={{
            height: "75px",
            display: { xs: "none", md: "none", lg: "block" },
          }}
        />
      </Link>

      <Box sx={{ flexGrow: 1 }} />

      <Stack
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "none", lg: "flex" },
        }}
        direction="row"
        display="flex"
        justifyContent="center"
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <StyledNavItem
          className="nav-text"
          component={RouterLink}
          to="/hrpage"
          sx={{
            fontSize: "16px",
            "&.active": {
              color: "#c60c0c",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText sx={{ paddingLeft: 3 }} disableTypography>
            <Trans i18nKey="humanresources" />
          </ListItemText>
        </StyledNavItem>
        <StyledNavItem
          className="nav-text"
          component={RouterLink}
          to="/knowledgepage"
          sx={{
            fontSize: "16px",
            "&.active": {
              color: "#c60c0c",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText sx={{ paddingLeft: 3 }} disableTypography>
            <Trans i18nKey="knowledge" />
          </ListItemText>
        </StyledNavItem>
        <StyledNavItem
          className="nav-text"
          component={RouterLink}
          to="/aboutpage"
          sx={{
            fontSize: "16px",
            "&.active": {
              color: "#c60c0c",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText sx={{ paddingLeft: 3 }} disableTypography>
            <Trans i18nKey="about" />
          </ListItemText>
        </StyledNavItem>
        <StyledNavItem
          className="nav-text"
          component={RouterLink}
          to="/contactpage"
          sx={{
            fontSize: "16px",
            "&.active": {
              color: "#c60c0c",
              bgcolor: "action.selected",
              fontWeight: 600,
            },
          }}
        >
          <ListItemText sx={{ paddingLeft: 3 }} disableTypography>
            <Trans i18nKey="contact" />
          </ListItemText>
        </StyledNavItem>
        {localStorage.getItem("user") ? (
          <StyledNavItem
            className="nav-text"
            component={RouterLink}
            to="/admin/workall"
            sx={{
              fontSize: "16px",
              "&.active": {
                color: "#c60c0c",
                bgcolor: "action.selected",
                fontWeight: 600,
              },
            }}
          >
            <ListItemText sx={{ paddingLeft: 3 }} disableTypography>
              Admin Page
            </ListItemText>
          </StyledNavItem>
        ) : (
          <></>
        )}
        <Language />
        <Theme colorMode={colorMode} />
        <Box mx={2}>
          {localStorage.getItem("user") ? <AccountPopover /> : <></>}
        </Box>
      </Stack>
    </StyledToolbar>
  );
}

import {
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { WorkHead, WorkRow, WorkTool } from "../tablework";
import { useMediaQuery } from "react-responsive";
// import work from '../../fake/data.json'
import { Trans } from "react-i18next";
import { applySortFilter, getComparator } from "../../utils/table_help";
// import axios from 'axios';

export default function Work(props) {
  const { workData, handlePageNow } = props;

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(`date`);

  const [filterName, setFilterName] = useState("");

  const [searchPositon, setSearchPositon] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [searchProvince, setSearchProvince] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 721px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  let TABLE_HEAD = [];
  if (isMobile) {
    TABLE_HEAD = [
      { id: "positionW", label: "ตำแหน่ง" },
      { id: "companyName", label: "บริษัท" },
      { id: "rate_em", label: "รับสมัครอัตรา", numeric: true },
    ];
  } else if (isDesktopOrLaptop) {
    TABLE_HEAD = [
      { id: "id", label: "ลำดับ" },
      { id: "positionW", label: "ตำแหน่ง" },
      { id: "companyName", label: "บริษัท" },
      { id: "province", label: "จังหวัด" },
      { id: "rate_em", label: "รับสมัครอัตรา", numeric: true },
      { id: "date", label: "วันที่ลง" },
    ];
  }

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = workData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleFilterByName = (e) => {
    setPage(0);
    setFilterName(e.target.value);
  };

  const handleProvince = (e, value) => {
    setSearchProvince(e.target.value);
    // setSearchProvince(value);
  };

  const handlePositon = (e, value) => {
    setSearchPositon(e.target.value);
    // setSearchPositon(value);
  };

  const handleCompany = (e) => {
    setSearchCompany(e.target.value);
  };

  const emptyRows = workData.length === 0;

  const filteredWorks = applySortFilter(
    workData,
    getComparator(order, orderBy),
    filterName,
    searchPositon,
    searchCompany,
    searchProvince
  );

  const isNotFound = !filteredWorks.length && !!filterName;

  return (
    <React.Fragment>
      <Stack mx={{ md: 10 }} my={10}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={2}
          mx={2}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: "48px", fontWeight: 600, textAlign: "center" }}
          >
            <Trans i18nKey="worktabletitlehome" />
          </Typography>
        </Stack>
        <Card>
          <WorkTool
            workData={workData}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPositon={searchPositon}
            onPosition={handlePositon}
            searchCompany={searchCompany}
            onCompany={handleCompany}
            searchProvince={searchProvince}
            onProvince={handleProvince}
          />
          <TableContainer sx={{ minWidth: "auto" }}>
            <Table>
              <WorkHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={workData.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredWorks
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <WorkRow
                        row={row}
                        key={row.id}
                        index={index}
                        handlePageNow={handlePageNow}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      <Typography variant="h6" paragraph>
                        ไม่มี
                      </Typography>

                      <Typography variant="body2">ข้อมูลว่างเปล่า</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Typography variant="h6" paragraph>
                        ไม่เจอ
                      </Typography>
                      <Typography variant="body2">
                        ไม่เจอข้อมูลที่ &nbsp;
                        <strong>
                          &quot;คุณกำลังค้นหาตำแหน่งงานที่ต้องการ&quot;
                        </strong>
                        .
                        <br />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={workData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Stack>
    </React.Fragment>
  );
}

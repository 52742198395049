import React from "react";
import {
    TableRow,
    TableCell,
} from "@mui/material";

export default function WorkRowSmall(props) {

    const { row, handlePageNow } = props

    return (
        <>
            <TableRow tabIndex={-1} key={row.id} hover id="viewwork" onClick={(e) => handlePageNow(e, row.id)} sx={{ cursor: 'pointer' }}>
                <TableCell label="Name" align="left" width="15%" id="viewwork">
                    {row.positionW}
                </TableCell>

                <TableCell align="left" width="12%" id="viewwork">
                    {row.companyName}
                </TableCell>

                <TableCell align="left" width="12%" id="viewwork">
                    {row.province}
                </TableCell>
            </TableRow>
        </>
    )
}

import { Helmet } from "react-helmet-async";
import React, { useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Paper,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

// ----------------------------------------------------------------------
const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  backgroundImage: `url('Stamp_BG_5.png')`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0, 0, 0),
}));

// ----------------------------------------------------------------------
export default function LoginPage() {
  const [user, setUserName] = useState("");
  const [pass, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (user === 'admin' && pass === 'admin') {
    //     alert("Success");
    //     localStorage.setItem('user', JSON.stringify({ user: 'admin', password: "admin", company: "UOE4289" }))
    //     navigate('/')
    //     window.location.reload(false);
    // }
    // else {
    //     alert('Error Mistake Username Or Password')
    // }
    fetch(`${process.env.REACT_APP_API}/users`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        if (resp.username === user && resp.password === pass) {
          swal("Success", "Login Success", "success").then((value) => {
            localStorage.setItem("user", JSON.stringify(resp.username));
            if (localStorage.getItem("user")) {
              navigate("/");
              window.location.reload(false);
            }
          });
        } else {
          swal("Failed", "Error Mistake Username Or Password", "error");
        }
      })
      .catch((err) => {
        swal("Failed", "Login Failed due to :" + err.message, "error");
      });
  };

  return (
    <>
      <Helmet>
        <title> เข้าสู่ระบบ | UOE4829 </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Paper elevation={3}>
              <Stack mx={4} my={4}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    component="img"
                    src="/assets/images/uoe4289/logo.svg"
                    sx={{ width: 150, height: 150 }}
                  />
                </Box>

                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    fontSize: "24px !important",
                    marginLeft: 1,
                  }}
                >
                  Sign In
                </Typography>

                <Box component="form" onSubmit={handleSubmit}>
                  <Stack spacing={3} my={2}>
                    <TextField
                      name="username"
                      label="Username"
                      value={user}
                      onChange={(e) => setUserName(e.target.value)}
                    />

                    <TextField
                      name="password"
                      label="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                  </Stack>

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </LoadingButton>
                </Box>
              </Stack>
            </Paper>
            {/* <Footer /> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

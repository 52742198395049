import React from 'react'
import { Helmet } from "react-helmet-async";
import { ContactForm, ContactPay, ContactTitle } from '../comonents/contact';

export default function ContactPage() {
    return (
        <React.Fragment>
            <Helmet>
                <title> ติดต่อเรา | UOE4289 </title>
            </Helmet>

            <ContactTitle />
            <ContactPay />
            <ContactForm />
        </React.Fragment>
    )
}

import {
  Box,
  Button,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Information, Position, Welfare } from "../../comonents/formwork";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";

export default function EditWork(props) {
  const { row } = props;

  const steps = [
    "บริษัทและรายละเอียด",
    "ตำแหน่งงานและรายละเอียด",
    "สวัสดิการและรายละเอียด",
  ];
  const [activeStep, setActiveStep] = useState(0);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const [inputWork, setInputWork] = useState({
    companyName: `${row.companyName}`,
    logo: `${row.logo}`,
    companyType: `${row.companyType}`,
    email: `${row.email}`,
    phone: row.phone,
    detailCompany: `${row.detailCompany}`,
    zipcode: `${row.zipcode}`,
    subdist: `${row.subdist}`,
    dist: `${row.dist}`,
    province: `${row.province}`,

    departmentW: `${row.departmentW}`,
    positionW: `${row.positionW}`,
    rate_em: `${row.rate_em}`,
    education: `${row.education}`,
    dateW: `${row.dateW}`,
    timeW: `${row.timeW}`,
    salaryW: `${row.salaryW}`,
    detailPosition: `${row.detailPosition}`,

    detailWelfare: `${row.detailWelfare}`,
    date: moment().format("MM/DD/YYYY : HH:mm:ss"),
    expiredate: moment().add(3, "month").format("MM/DD/YYYY : HH:mm:ss"),
  });

  console.log(row.phone);

  const [errorWork, setErrorWork] = useState({
    companyName: "",
    zipcode: "",
    subdist: "",

    departmentW: "",
    positionW: "",
    rate_em: "",
    salaryW: "",
  });

  const Data1 = [
    {
      id: 1,
      name: "ชื่อบริษัท",
      value: inputWork.companyName,
    },
    {
      id: 2,
      name: "โลโก้บริษัท",
      value: inputWork.logo,
    },
    {
      id: 3,
      name: "ประเภทบริษัท",
      value: inputWork.companyType,
    },
    {
      id: 4,
      name: "อีเมล",
      value: inputWork.email,
    },
    {
      id: 5,
      name: "เบอร์โทรศัพท์",
      value: inputWork.phone,
    },
    {
      id: 6,
      name: "รายละเอียดที่อยู่บริษัท",
      value: inputWork.detailCompany,
    },
    {
      id: 7,
      name: "รหัสไปรษณีย์",
      value: inputWork.zipcode,
    },
    {
      id: 8,
      name: "ตำบล/แขวง",
      value: inputWork.subdist,
    },
    {
      id: 9,
      name: "อำเภอ/เขต",
      value: inputWork.dist,
    },
    {
      id: 10,
      name: "จังหวัด",
      value: inputWork.province,
    },
  ];

  const Data2 = [
    {
      id: 1,
      name: "แผนกงานที่",
      value: inputWork.departmentW,
    },
    {
      id: 2,
      name: "ตำแหน่งงาน",
      value: inputWork.positionW,
    },
    {
      id: 3,
      name: "อัตรารับสมัคร",
      value: `${inputWork.rate_em} คน`,
    },
    {
      id: 4,
      name: "ระดับการศึกษา",
      value: inputWork.education,
    },
    {
      id: 5,
      name: "ช่วงวันทำงาน",
      value: inputWork.dateW,
    },
    {
      id: 6,
      name: "ช่วงเวลาทำงาน",
      value: inputWork.timeW,
    },
    {
      id: 7,
      name: "เงินเดือน",
      value: `${inputWork.salaryW} บาท`,
    },
    {
      id: 8,
      name: "รายละเอียดตำแหน่งงาน",
      value: inputWork.detailPosition,
    },
  ];

  const handleNext = () => {
    if (activeStep === 0) {
      if (inputWork.companyName && inputWork.zipcode && inputWork.subdist) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        if (!inputWork.companyName) {
          setErrorWork({ ...errorWork, companyName: "กรุณาใส่ชื่อบริษัท*" });
        } else if (!inputWork.zipcode) {
          setErrorWork({ ...errorWork, zipcode: "กรุณาใส่รหัสไปรษณีย์*" });
        } else if (!inputWork.subdist) {
          setErrorWork({ ...errorWork, subdist: "กรุณาเลือกตำบล*" });
        }
      }
    }
    if (activeStep === 1) {
      if (
        inputWork.departmentW &&
        inputWork.positionW &&
        inputWork.rate_em &&
        inputWork.salaryW
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        if (!inputWork.departmentW) {
          setErrorWork({ ...errorWork, departmentW: "กรุณาเลือกแผนก" });
        } else if (!inputWork.positionW) {
          setErrorWork({ ...errorWork, positionW: "กรุณาเลือกตำแหน่งงาน" });
        } else if (!inputWork.rate_em) {
          setErrorWork({ ...errorWork, rate_em: "กรุณากรอกอัตรารับสมัคร" });
        } else if (!inputWork.salaryW) {
          setErrorWork({ ...errorWork, salaryW: "กรุณากรอกเงินเดือน" });
        }
      }
    }
    if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePostWork = (e) => {
    e.preventDefault();
    const formData = new FormData();
    axios
      .put(`${process.env.REACT_APP_API}/data/${row.id}`, inputWork)
      .then((res) => {
        if (file) {
          formData.append("companyName", inputWork.companyName);
          formData.append("positionW", inputWork.positionW);
          formData.append("file", file);
          axios
            .post(`${process.env.REACT_APP_FILE_API}/upload`, formData)
            .then((resf) => {
              swal(
                "Success",
                "คุณแก้ไขข้อมูลแบบมี logo ได้สำเร็จ",
                "success"
              ).then((resp) => {
                window.location.href = "workall";
              });
            });
        } else {
          swal(
            "Success",
            "คุณแก้ไขข้อมูลแบบไม่มี logo ได้สำเร็จ",
            "success"
          ).then((resp) => {
            window.location.href = "workall";
          });
        }
      })
      .catch((err) => {
        swal("Error", "เกิดปัญหาเรื่อง Demo Database", "error");
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title> แก้ไขงาน | Admin UOE4289 </title>
      </Helmet>

      <Stack mx={2} mb={2}>
        <Paper>
          <Stack my={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontSize: "34px", fontWeight: 600 }}>
                เพิ่มข้อมูลรับสมัครตำแหน่งงานของบริษัท
              </Typography>
            </Box>
            <Stack mx={4}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Box
                id="form"
                name="form"
                component="form"
                onSubmit={handlePostWork}
              >
                {activeStep === 0 ? (
                  <Information
                    inputWork={inputWork}
                    setInputWork={setInputWork}
                    errorWork={errorWork}
                    setErrorWork={setErrorWork}
                    setImage={setImage}
                    file={file}
                    setFile={setFile}
                  />
                ) : activeStep === 1 ? (
                  <Position
                    inputWork={inputWork}
                    setInputWork={setInputWork}
                    errorWork={errorWork}
                    setErrorWork={setErrorWork}
                  />
                ) : activeStep === 2 ? (
                  <Welfare inputWork={inputWork} setInputWork={setInputWork} />
                ) : (
                  <></>
                )}
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginY: 3,
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          width: { md: "25%", sm: "50%", xs: "100%" },
                          height: "auto",
                        }}
                        src={image}
                      />
                    </Box>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={12} md={4}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          ข้อมูลบริษัทและรายละเอียด
                        </Typography>
                        {Data1.map((item) => {
                          return (
                            <Box key={item.id}>
                              <Typography
                                variant="body1"
                                className="textcolor"
                                fontWeight={600}
                                fontSize={16}
                              >
                                {" "}
                                {item.name} :
                                <Typography
                                  variant="span"
                                  className="textcolor"
                                  component="span"
                                  fontWeight={300}
                                >
                                  {" "}
                                  {item.value}
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                      </Grid>
                      <Grid xs={12} sm={12} md={4}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          ข้อมูลตำแหน่งงานและรายละเอียด
                        </Typography>
                        {Data2.map((item) => {
                          return (
                            <Box key={item.id}>
                              <Typography
                                variant="body1"
                                className="textcolor"
                                fontWeight={600}
                                fontSize={16}
                              >
                                {" "}
                                {item.name} :
                                <Typography
                                  variant="span"
                                  className="textcolor"
                                  component="span"
                                  fontWeight={300}
                                >
                                  {" "}
                                  {item.value}
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                      </Grid>
                      <Grid xs={12} sm={12} md={4}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          ข้อมูลสวัสดิการและรายละเอียด
                        </Typography>
                        <Box>
                          <Typography
                            variant="body1"
                            className="textcolor"
                            fontWeight={600}
                            fontSize={16}
                          >
                            {" "}
                            รายละเอียดสวัสดิการ :
                            <Typography
                              variant="span"
                              className="textcolor"
                              component="span"
                              fontWeight={300}
                            >
                              {" "}
                              {inputWork.detailWelfare}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />

                      <Button type="submit" variant="contained" color="success">
                        เพิ่ม
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />

                      <Button
                        onClick={handleNext}
                        variant="contained"
                        color="primary"
                      >
                        ต่อไป
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </React.Fragment>
  );
}

import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import Cleave from "cleave.js/react";

const CleaveInput = forwardRef((props, ref) => {
  const { inputRef, ...other } = props;

  return <Cleave {...other} htmlRef={inputRef} />;
});

const InputTextMasked = (props) => {
  const { options, ...rest } = props;
  return (
    <TextField
      InputProps={{
        inputComponent: CleaveInput,
        inputProps: {
          options,
          "data-testid": props.name
        }
      }}
      {...rest}
    />
  );
};

export default InputTextMasked;

import React from 'react'
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
    Toolbar,
    OutlinedInput,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Button,
    // Autocomplete,
    // TextField,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// component
import Iconify from "../../../comonents/iconify";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(["box-shadow", "width"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": {
        width: 320,
        // boxShadow: theme.customShadows.z8,
    },
    "& fieldset": {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));


export default function WorkTool(props) {

    const { numSelected, filterName, onFilterName, searchPositon, onPosition, searchCompany, onCompany, searchProvince, onProvince, work, handlePageNow } = props
    return (
        <>
            <Stack mx={4} my={4}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={4} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                        <FormControl className="form-select" sx={{ width: "100%" }}>
                            <InputLabel>จังหวัด</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                name="province"
                                value={searchProvince}
                                onChange={onProvince}
                                label="จังหวัด"
                            >
                                <MenuItem value=''>ไม่เลือก</MenuItem>
                                {work.reduce((x, y) => x.includes(y.province) ? x : [...x, y.province], []).map((item) => {
                                    return (
                                        <MenuItem value={item} key={item}>{item}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={4}>
                        <FormControl className="form-select" sx={{ width: "100%" }}>
                            <InputLabel>ตำแหน่ง</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                name="positionW"
                                value={searchPositon}
                                onChange={onPosition}
                                label="ตำแหน่ง"
                            >
                                <MenuItem value=''>ไม่เลือก</MenuItem>
                                {work.reduce((x, y) => x.includes(y.positionW) ? x : [...x, y.positionW], []).map((item) => {
                                    return (
                                        <MenuItem value={item} key={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={4}>
                        <FormControl className="form-select" sx={{ width: "100%" }}>
                            <InputLabel>บริษัท</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                name="companyName"
                                value={searchCompany}
                                onChange={onCompany}
                                label="บริษัท"
                            >
                                <MenuItem value=''>ไม่เลือก</MenuItem>
                                {work.reduce((x, y) => x.includes(y.companyName) ? x : [...x, y.companyName], []).map((item) => {
                                    return (
                                        <MenuItem value={item} key={item}>{item}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Stack>
            <StyledRoot
                sx={{
                    ...(numSelected > 0 && {
                        color: "primary.main",
                        bgcolor: "primary.lighter",
                    }),
                    display: "flex", justifyContent: "space-between"
                }}
            >
                <StyledSearch
                    className="search"
                    value={filterName}
                    onChange={onFilterName}
                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify
                                icon="eva:search-fill"
                                sx={{ color: "text.disabled", width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    }
                />
                <Button variant='contained' id='addwork' onClick={handlePageNow} size='large'>เพิ่มงาน</Button>
            </StyledRoot>
        </>
    )
}

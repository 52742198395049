import React, { useState } from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Card, CardContent, CardMedia, Container, Divider, Pagination, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import Blog from '../../fake/blog.json'
import moment from 'moment/moment';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
export default function KnowledgeNew() {

    const [page, setPage] = useState(1);
    const rowsPerPage = 3;

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    return (
        <Container>
            <Stack my={6}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={8} >
                        {Blog.sort((a, b) => a.id > b.id ? -1 : 1).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((item) => {
                            return (
                                <Card sx={{ marginBottom: "20px", }} key={item.id} id={item.id}>
                                    <Stack mx={2} my={2}>
                                        <Box className='cardcontainer' sx={{ position: "relative", width: "100%" }}>
                                            <Box component="a" href={`blogpage/${item.id}`}>
                                                <CardMedia className='cardimage' component='img' src={item.img} alt='44' height="auto" width="100%" />
                                                <Box component="div" className='middle'>
                                                    <Typography className='textShowNews'> <Iconify icon='tabler:circle-arrow-right-filled' width='80px' /> </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <CardContent>
                                            {item.detail}
                                        </CardContent>
                                        <Divider />
                                        <CardContent>{moment(item.footer, "MM/DD/YYYY : HH:mm:ss", i18next.language).format('LLLL')}</CardContent>
                                    </Stack>
                                </Card>
                            )
                        })}
                        <Pagination
                            count={Blog.length / rowsPerPage}
                            size="large"
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={4}>
                        <Typography variant='h2' fontSize="34px" fontWeight={600}><Trans i18nKey="newtitleknowledge" /></Typography>
                        <Box my={4}>
                            <Typography variant='body1'><Trans i18nKey="newdetailknowledge" /></Typography>
                        </Box>
                        <Stack direction="row" spacing={2} mt={4} flexWrap="wrap">
                            <Box sx={{ margin: "10px !important" }}>
                                <Iconify icon="devicon-plain:facebook" width="50px" />
                            </Box>
                            <Box sx={{ margin: "10px !important" }}>
                                <Iconify icon="vaadin:twitter-square" width="50px" />
                            </Box>
                            <Box sx={{ margin: "10px !important" }}>
                                <Iconify icon="icomoon-free:google-plus2" width="50px" />
                            </Box>
                            <Box sx={{ margin: "10px !important" }}>
                                <Iconify icon="fa:pinterest-square" width="50px" />
                            </Box>
                            <Box sx={{ margin: "10px !important" }}>
                                <Iconify icon="teenyicons:instagram-solid" width="50px" />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    )
}

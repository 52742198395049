import { Box, Container, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'

export default function Welfare(props) {

  const { inputWork, setInputWork } = props

  const handleInputWork = (e) => {
    setInputWork({ ...inputWork, [e.target.name]: e.target.value })
  }
  return (
    <React.Fragment>
      <Box>
        <Container>
          <Grid container spacing={2} my={3}>
            <Grid xs={12} sm={12} md={12}>
              <TextField
                name='detailWelfare'
                id='detailWelfare'
                sx={{ width: "100%" }}
                label="รายละเอียดสวัสดิการ"
                multiline
                rows={2}
                value={inputWork.detailWelfare}
                onChange={handleInputWork}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  )
}

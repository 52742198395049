import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export default function ResumeForm(props) {
  const { workData, pageId } = props;
  var row = workData
    .filter((value) => value.id === parseInt(pageId))
    .map((item) => item);

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack my={2}>
          <Stack my={4}>
            <Box sx={{ marginBottom: "30px", marginLeft: "30px" }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  fontSize: "24px !important",
                  marginLeft: 1,
                }}
              >
                เพิ่ม Resume สำหรับติดต่อบริษัท {row[0].companyName} ตำแหน่ง{" "}
                {row[0].positionW}
              </Typography>
            </Box>
            <Stack spacing={3} my={2} mx={{ md: 20 }}>
              <iframe
                src={process.env.REACT_APP_UOE4289_API}
                title="description"
                width="100%"
                height="1000px"
              ></iframe>
            </Stack>
          </Stack>
        </Stack>
      </LocalizationProvider>
    </React.Fragment>
  );
}

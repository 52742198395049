import React from 'react'
import { Helmet } from "react-helmet-async";
import { KnowledgeNew, KnowledgeTitle } from '../comonents/knowledge-center';

export default function KnowledgePage() {
    return (
        <>
            <Helmet>
                <title> สาระความรู้ | UOE4289 </title>
            </Helmet>

            <KnowledgeTitle />
            <KnowledgeNew />
        </>
    )
}

import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Paper, Stack, Typography, Chip, Container, Badge, Autocomplete, TextField, InputAdornment, Avatar } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

const sr = [
    {
        id: 1,
        name: "searchregistersaleshr",
        value: 17079
    },
    {
        id: 2,
        name: "searchregisteradminhr",
        value: 3915
    },
    {
        id: 3,
        name: "searchregisteraccounthr",
        value: 3446
    },
    {
        id: 4,
        name: "searchregisterqchr",
        value: 4856
    },
    {
        id: 5,
        name: "searchregisterengineerhr",
        value: 4742
    },
    {
        id: 6,
        name: "searchregistermarkethr",
        value: 2692
    },
    {
        id: 7,
        name: "searchregisterprogramhr",
        value: 3186
    },

    {
        id: 8,
        name: "searchregisterlogisticshr",
        value: 2555
    },
    {
        id: 9,
        name: "searchregisterhrhr",
        value: 1291
    },
    {
        id: 10,
        name: "searchregisterfinancehr",
        value: 3191
    },
    {
        id: 11,
        name: "searchregisterparttimehr",
        value: 1124
    },
    {
        id: 12,
        name: "searchregistermobilehr",
        value: 112
    },
    {
        id: 13,
        name: "searchregisterrealestatehr",
        value: 2845
    },

    {
        id: 14,
        name: "searchregisterautomativehr",
        value: 4975
    },
    {
        id: 15,
        name: "searchregisterchinesehr",
        value: 1749
    },
    {
        id: 16,
        name: "searchregistergraphichr",
        value: 886
    },
    {
        id: 17,
        name: "searchregisternewgraduateshr",
        value: 6191
    },
]

const person = [
    {
        id: 1,
        avatar: "/assets/images/uoe4289/img-01.svg",
        name: "Alfredo Bossard",
        subname: "การโพสต์โฆษณาการป้อนข้อมูลการพิมพ์",
        money: 44.00,
        coun: "United States",
        reliability: "100",
        detail: "ทำ eiusmod veniamac และความมีชีวิตชีวาเพื่อให้การออกกำลังกายของผู้ชาย nostrud เขตการศึกษาและปีของการทำงานเป็นความคิดที่ดี",
        lan1: "PHP",
        lan2: "HTML",
        lan3: "JavaScript",
        lan4: "WordPress",
        lan5: "JQuery",
    },
    {
        id: 2,
        avatar: "/assets/images/uoe4289/img-02.svg",
        name: "Marcelene Westerberg",
        subname: "ผู้เชี่ยวชาญด้านการตลาดโซเชียลมีเดีย SEO / PPC",
        money: 44.00,
        coun: "United States",
        reliability: "100",
        detail: "ทำ eiusmod veniamac และความมีชีวิตชีวาเพื่อให้การออกกำลังกายของผู้ชาย nostrud เขตการศึกษาและปีของการทำงานเป็นความคิดที่ดี",
        lan1: "PHP",
        lan2: "HTML",
        lan3: "SEO",
        lan4: "WordPress",
        lan5: "Team Management",
    },
    {
        id: 3,
        avatar: "/assets/images/uoe4289/img-03.svg",
        name: "Vance Applebaum",
        subname: "นักพัฒนาที่มีทักษะเต็มรูปแบบ",
        money: 44.00,
        coun: "United States",
        reliability: "100",
        detail: "ทำ eiusmod veniamac และความมีชีวิตชีวาเพื่อให้การออกกำลังกายของผู้ชาย nostrud เขตการศึกษาและปีของการทำงานเป็นความคิดที่ดี",
        lan1: "PHP",
        lan2: "HTML",
        lan3: "JavaScript",
        lan4: "WordPress",
        lan5: "SEO",
    },
    {
        id: 4,
        avatar: "/assets/images/uoe4289/img-05.svg",
        name: "Herlinda Hundley",
        subname: "ผู้บุกเบิกการป้อนข้อมูลอีคอมเมิร์ซ",
        money: 44.00,
        coun: "United States",
        reliability: "100",
        detail: "ทำ eiusmod veniamac และความมีชีวิตชีวาเพื่อให้การออกกำลังกายของผู้ชาย nostrud เขตการศึกษาและปีของการทำงานเป็นความคิดที่ดี",
        lan1: "PHP",
        lan2: "HTML",
        lan3: "JavaScript",
        lan4: "WordPress",
        lan5: "JQuery",
    },
]

export default function SearchRegister() {
    return (
        <Stack my={8}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant='h1' color="#c60c0c" fontSize="34px" fontWeight={600}><Trans i18nKey="searchregistertitlehr" /></Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant='body1' color="#898989" fontSize="16px" textAlign="center"><Trans i18nKey="searchregistersubhr" /></Typography>
            </Box>
            <Container>
                <Stack spacing={3} mt={4}>
                    <Stack direction="row" spacing={4} flexWrap="wrap">
                        {sr.map((item) => {
                            return (
                                <Box key={item.id} sx={{ margin: "10px !important" }}>
                                    <Badge color="error" badgeContent={item.value} max={99999}>
                                        <Chip sx={{ fontSize: "18px" }} label={<Trans i18nKey={item.name} />} />
                                    </Badge>
                                </Box>
                            )
                        })}
                    </Stack>
                </Stack>
                <Box mt={4}>
                    <Autocomplete
                        className='autocompletebg'
                        freeSolo
                        disableClearable
                        options={top100Films.map((option) => option.title)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='ค้นหาหมวดหมู่'
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    startAdornment: <InputAdornment position="start"><Iconify icon="material-symbols:search" /></InputAdornment>,
                                }}
                            />
                        )}
                    />
                </Box>
                <Grid container spacing={2}>
                    {person.map((item) => {
                        return (
                            <Grid xs={12} sm={12} md={6} key={item.id}>
                                <Stack mt={3}>
                                    <Paper>
                                        <Stack mx={4} my={8}>
                                            <Grid container spacing={1}>
                                                <Grid xs={3} sm={2} md={2}>
                                                    <Avatar src={item.avatar} variant="square" sx={{ width: 56, height: 56 }} />
                                                </Grid>
                                                <Grid xs={9} sm={10} md={10}>
                                                    <Typography variant='h5' fontSize="20px" color="#c60c0c"><Box component="img" src='/assets/images/uoe4289/sdw.svg' alt='ssss' height="15px" />{item.name}</Typography>
                                                    <Typography variant='body1' fontSize="16px">{item.subname}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Stack direction="row" spacing={2} mt={4} flexWrap="wrap">
                                                <Box sx={{ margin: "10px !important" }}>
                                                    <Chip icon={<Iconify icon="material-symbols:attach-money" />} sx={{ fontSize: "16px" }} label={`${item.money} / hr`} />
                                                </Box>
                                                <Box sx={{ margin: "10px !important" }}>
                                                    <Chip icon={<Iconify icon="flag:us-4x3" />} sx={{ fontSize: "16px" }} label={item.coun} />
                                                </Box>
                                                <Box sx={{ margin: "10px !important" }}>
                                                    <Chip icon={<Iconify icon="ri:hand-heart-fill" />} sx={{ fontSize: "16px" }} label={`reliability : ${item.reliability}%`} />
                                                </Box>
                                            </Stack>
                                            <Box my={4}>
                                                <Typography>
                                                    {item.detail}
                                                </Typography>
                                            </Box>
                                            <Stack direction="row" spacing={2} flexWrap="wrap">
                                                <Box sx={{ margin: "5px !important" }}>
                                                    <Chip sx={{ fontSize: "14px" }} label={item.lan1} />
                                                </Box>
                                                <Box sx={{ margin: "5px !important" }}>
                                                    <Chip sx={{ fontSize: "14px" }} label={item.lan2} />
                                                </Box>
                                                <Box sx={{ margin: "5px !important" }}>
                                                    <Chip sx={{ fontSize: "14px" }} label={item.lan3} />
                                                </Box>
                                                <Box sx={{ margin: "5px !important" }}>
                                                    <Chip sx={{ fontSize: "14px" }} label={item.lan4} />
                                                </Box>
                                                <Box sx={{ margin: "5px !important" }}>
                                                    <Chip sx={{ fontSize: "14px" }} label={item.lan5} />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Paper>
                                </Stack>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container >

        </Stack >
    )
}

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
        title: 'The Lord of the Rings: The Return of the King',
        year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
        title: 'The Lord of the Rings: The Fellowship of the Ring',
        year: 2001,
    },
    {
        title: 'Star Wars: Episode V - The Empire Strikes Back',
        year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    {
        title: 'The Lord of the Rings: The Two Towers',
        year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    {
        title: 'Star Wars: Episode IV - A New Hope',
        year: 1977,
    },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    {
        title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
        year: 1964,
    },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    {
        title: 'Star Wars: Episode VI - Return of the Jedi',
        year: 1983,
    },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    {
        title: 'Eternal Sunshine of the Spotless Mind',
        year: 2004,
    },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
];
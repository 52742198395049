import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet-async";
import { Ads, Why, PositionWork, ChooseWork, Ads2, ThreeProcess, Work, HomeTitle } from '../comonents/homepage'; //Comment,
import DetailWorkPage from './DetailWorkPage';
import ResumePage from './ResumePage';
import moment from 'moment';
import axios from 'axios';
export default function HomePage() {
    const [workData, setWorkData] = useState([])

    const fetchData = () => {
        fetch(`${process.env.REACT_APP_API}/data`).then((res) => {
            return res.json()
        }).then((resp) => {
            const dData = resp.filter((days) =>
                moment(days.expiredate, "MM/DD/YYYY : HH:mm:ss").diff(moment(), 'days') === 0).map((item) => item.id)
            // console.log(dData)
            for (var i = 0; i < dData.length; i++) {
                axios.delete(`${process.env.REACT_APP_API}/data/` + dData[i]).then((res) => res).catch((err) => err)
            }
            return setWorkData(resp)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const [pageNow, setPageNow] = useState('default')
    const [pageId, setPageId] = useState(0)
    const handlePageNow = (e, ids) => {
        if (e.target.id === 'default') {
            setPageNow(e.target.id)
            setPageId(0)
        }
        setPageNow(e.target.id)
        setPageId(ids)
    }

    return (
        <>
            {pageNow === 'default' && pageId === 0 ?
                <>
                    <Helmet>
                        <title> หน้าหลัก | UOE4289 </title>
                    </Helmet>
                    <HomeTitle />
                    <Work workData={workData} handlePageNow={handlePageNow} />
                    <Why />
                    <Ads />
                    <PositionWork />
                    <ChooseWork />
                    <Ads2 />
                    <ThreeProcess />
                    {/* <Comment /> */}
                </> : pageNow === 'viewwork' ?
                    <>
                        <DetailWorkPage workData={workData} pageId={pageId} handlePageNow={handlePageNow} />
                    </> : pageNow === 'resumework' ?
                        <>
                            <ResumePage workData={workData} pageId={pageId} handlePageNow={handlePageNow} />
                        </> : <>Error</>}

        </>
    )
}

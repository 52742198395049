import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Part1, Part10, Part2, Part3, Part4, Part5, Part6, Part7, Part8, Part9 } from "../comonents/cookie";
import TabLink from '../comonents/linkprivacy/TabLink';

export default function CookiePage() {

  return (
    <React.Fragment>
      <Helmet>
        <title> Cookie | UOE4289 </title>
      </Helmet>

      <Stack>
        <Container>
          <Paper elevation={3}>
            <Stack mx={6} my={3}>
              <Box my={3}>
                <TabLink />
              </Box>
              <Box my={3}>
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>นโยบายคุกกี้</Typography>
              </Box>
              <Divider />
              <Box my={3}>
                <Typography className="text-bold">นโยบายความเป็นส่วนตัวของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">1.</Typography> บทนำ</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">2.</Typography> ข้อมูลส่วนบุคคลที่เก็บรวบรวม</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">3.</Typography>	การนำข้อมูลส่วนบุคคลไปใช้</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">4.</Typography>	วิธีการเผยแพร่ข้อมูลส่วนบุคคล</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">5.</Typography>	การคุ้มครองข้อมูลส่วนบุคคล และสิทธิของเจ้าของข้อมูล</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">6.</Typography>	การลบข้อมูลส่วนบุคคล</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">7.</Typography>	วิธีการเก็บรักษาข้อมูลส่วนบุคคล</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">8.</Typography>	การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</Typography>
                <Typography className="text-normal"><Typography component="span" className="text-bold">9.</Typography>	ติดต่อเรา</Typography>
              </Box>
              <Divider />
              {/* 1 */}
              <Part1 />
              {/* 2 */}
              <Part2 />
              {/* 3 */}
              <Part3 />
              {/* 4 */}
              <Part4 />
              {/* 5 */}
              <Part5 />
              {/* 6 */}
              <Part6 />
              {/* 7 */}
              <Part7 />
              {/* 8 */}
              <Part8 />
              {/* 9 */}
              <Part9 />
              {/* 10 */}
              <Part10 />
            </Stack>
          </Paper>
        </Container>
      </Stack>
    </React.Fragment>
  );
}

import React, { useEffect } from 'react'
import { Helmet } from "react-helmet-async";
import { ResumeForm, ResumeTitle } from '../comonents/resume';

export default function ResumePage(props) {

    const { workData, pageId, handlePageNow } = props

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title> ส่ง Resume | UOE4289 </title>
            </Helmet>

            <ResumeTitle />
            <ResumeForm workData={workData} pageId={pageId} handlePageNow={handlePageNow} />
        </React.Fragment>
    )
}

import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function PositionWork() {

    const positionW = [
        {
            id: 1,
            img: '/assets/images/uoe4289/coding.svg',
            rate: 'itratehome',
            text: 'ithome'
        },
        {
            id: 2,
            img: '/assets/images/uoe4289/Accounting.svg',
            rate: 'accountratehome',
            text: 'accounthome'
        },
        {
            id: 3,
            img: '/assets/images/uoe4289/foreman-control.svg',
            rate: 'logisticsratehome',
            text: 'logisticshome'
        },
        {
            id: 4,
            img: '/assets/images/uoe4289/construction.svg',
            rate: 'constructionratehome',
            text: 'constructionhome'
        },
        {
            id: 5,
            img: '/assets/images/uoe4289/insurance.svg',
            rate: 'insuranceworkratehome',
            text: 'insuranceworkhome'
        },
        {
            id: 6,
            img: '/assets/images/uoe4289/online-statistics_1098-15646.svg',
            rate: 'marketingratehome',
            text: 'marketinghome'
        },
        {
            id: 7,
            img: '/assets/images/uoe4289/manufacturing.svg',
            rate: 'productionlineratehome',
            text: 'productionlinehome'
        },
        {
            id: 8,
            img: '/assets/images/uoe4289/doctor-reviewing.svg',
            rate: 'healthratehome',
            text: 'healthhome'
        },
    ]
    return (
        <Box component="section" className='section-white' sx={{ backgroundColor: "white" }}>
            <Stack mx={3}>
                <Stack mx={2} my={6}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='h1' color="#c60c0c" fontSize="28px" fontWeight={600} textAlign="center"><Trans i18nKey="positiontitlehome" /></Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='body1' color="#898989" fontSize="16px" textAlign="center"><Trans i18nKey="positionsubhome" /></Typography>
                    </Box>
                    <Grid container spacing={1}>
                        {positionW.map((item) => {
                            return (
                                <Grid xs={12} sm={12} md={3} my={3} key={item.id}>
                                    <Box className='container_img' sx={{ position: "relative", height: "100%" }}>
                                        <Box component='img' className='image' src={item.img} alt='Accounting' sx={{ width: "100%", height: "100%" }} />
                                        <Box className='overlay' sx={{ width: "100%", height: "100%" }}>
                                            <Typography className='textShow' variant='body1' color="white" fontSize="20px" textAlign="center"><Trans i18nKey={item.rate} /></Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography variant='body1' color="#c60c0c" fontSize="16px" textAlign="center"><Trans i18nKey={item.text} /></Typography>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Box display="flex" justifyContent="center" mt={10}>
                        <Button color='error' variant="contained" startIcon={<Iconify icon="material-symbols:search" />} ><Trans i18nKey="positionbuttonhome" /></Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Iconify from "../iconify";
import AOS from "aos";
import { Trans } from "react-i18next";

export default function ContactForm() {
  const Contact = [
    {
      id: 1,
      icon: "mdi:telephone",
      title: "formtelcontact",
      detail: "+66 080-193-5295",
      href: "tel:+66801935295",
    },
    {
      id: 2,
      icon: "fa6-brands:line",
      title: "formlinecontact",
      detail: "@UOE4289",
      href: "",
    },
    {
      id: 3,
      icon: "devicon-plain:facebook",
      title: "formfacebookcontact",
      detail: "UOE4289",
      href: "https://www.facebook.com/profile.php?id=100089135664630",
    },
    {
      id: 4,
      icon: "material-symbols:mail-sharp",
      title: "formemailcontact",
      detail: "uoe4289@gmail.com",
      href: "mailto:uoe4289@gmail.com",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  const [inputMail, setInputMail] = useState({
    name: "",
    email: "",
    phone: "",
    work: "",
    title: "",
    detail: "",
  });

  const handleContactMail = (e) => {
    setInputMail({ ...inputMail, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputMail);
  };

  return (
    <React.Fragment>
      <Container>
        <Stack mb={4}>
          <Stack my={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h2"
                fontSize="34px"
                fontWeight={600}
                textAlign="center"
              >
                <Trans i18nKey="formtitlecontact" />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Typography variant="body1" fontSize="16px">
                <Trans i18nKey="formsub1contact" />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Typography variant="body1" fontSize="16px">
                <Trans i18nKey="formsub2contact" />
              </Typography>
            </Box>
            <Grid container spacing={2} mt={5}>
              <Grid xs={12} sm={12} md={6}>
                {Contact.map((item) => {
                  return (
                    <Grid
                      container
                      spacing={2}
                      xs={12}
                      sm={12}
                      md={12}
                      key={item.id}
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    >
                      <Grid xs={2} sm={2} md={2}>
                        <Box>
                          <Iconify icon={item.icon} width="50px" />
                        </Box>
                      </Grid>
                      <Grid xs={10} sm={10} md={10}>
                        <Box>
                          <Typography variant="body1" fontWeight={600}>
                            <Trans i18nKey={item.title} />
                          </Typography>
                          {item.href ? (
                            <Box component="a" href={item.href}>
                              <Typography variant="body1" color="#c60c0c">
                                {item.detail}
                              </Typography>
                            </Box>
                          ) : (
                            <Typography variant="body1">
                              {item.detail}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                >
                  <Grid xs={2} sm={2} md={2}>
                    <Box>
                      <Iconify icon="mdi:address-marker" width="50px" />
                    </Box>
                  </Grid>
                  <Grid xs={10} sm={10} md={10}>
                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        <Trans i18nKey="formaddresstitlecontact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress1contact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress2contact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress3contact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress4contact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress5contact" />
                      </Typography>
                      <Typography variant="body1">
                        <Trans i18nKey="formaddress6contact" />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                >
                  <Grid xs={2} sm={2} md={2}>
                    <Box>
                      <Iconify icon="ri:money-dollar-box-fill" width="50px" />
                    </Box>
                  </Grid>
                  <Grid xs={10} sm={10} md={10}>
                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        <Trans i18nKey="formtaxcontact" />
                      </Typography>
                      <Typography variant="body1">0105562174099</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <Stack>
                  <Paper elevation={4}>
                    <Stack mx={2} my={6}>
                      <Box>
                        <Typography variant="body1" fontWeight={600}>
                          <Trans i18nKey="formmailtitlecontact" />
                        </Typography>
                      </Box>
                      <Box component="form" onSubmit={handleSubmit}>
                        <Box sx={{ width: "100%" }} mt={3}>
                          <TextField
                            sx={{ width: "100%" }}
                            name="name"
                            required
                            label={<Trans i18nKey="formmailnamecontact" />}
                            value={inputMail.name}
                            onChange={handleContactMail}
                          />
                        </Box>
                        <Grid container spacing={2} mt={3}>
                          <Grid sm={12} md={6} sx={{ width: "100%" }}>
                            <TextField
                              sx={{ width: "100%" }}
                              name="email"
                              required
                              label={<Trans i18nKey="formmailemailcontact" />}
                              value={inputMail.email}
                              onChange={handleContactMail}
                            />
                          </Grid>
                          <Grid sm={12} md={6} sx={{ width: "100%" }}>
                            <TextField
                              sx={{ width: "100%" }}
                              name="phone"
                              required
                              label={<Trans i18nKey="formmailphonecontact" />}
                              value={inputMail.phone}
                              onChange={handleContactMail}
                            />
                          </Grid>
                        </Grid>
                        <Box mt={3}>
                          <FormControl fullWidth>
                            <InputLabel>
                              <Trans i18nKey="formmailwantcontact" />
                            </InputLabel>
                            <Select
                              value={inputMail.work}
                              name="work"
                              label={<Trans i18nKey="formmailwantcontact" />}
                              onChange={handleContactMail}
                            >
                              <MenuItem value="ตกลงสำหรับโซลูชันด้านทรัพยากรบุคคล">
                                <Trans i18nKey="formmailwantmenu1contact" />
                              </MenuItem>
                              <MenuItem value="ฉันกำลังมองหางาน">
                                <Trans i18nKey="formmailwantmenu2contact" />
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ width: "100%" }} mt={3}>
                          <TextField
                            sx={{ width: "100%" }}
                            name="title"
                            required
                            label={<Trans i18nKey="formmailheadercontact" />}
                            value={inputMail.title}
                            onChange={handleContactMail}
                          />
                        </Box>
                        <Box sx={{ width: "100%" }} mt={3}>
                          <TextField
                            sx={{ width: "100%" }}
                            required
                            label={<Trans i18nKey="formmaildetailcontact" />}
                            multiline
                            name="detail"
                            rows={4}
                            value={inputMail.detail}
                            onChange={handleContactMail}
                          />
                        </Box>
                        <Box
                          mt={3}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            type="submit"
                            color="warning"
                            variant="contained"
                            size="large"
                          >
                            <Trans i18nKey="formmailbuttoncontact" />
                          </Button>
                        </Box>
                      </Box>
                    </Stack>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
      <Box>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31002.919317249507!2d100.565697!3d13.756854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f051048ff4f%3A0x410d76724ba68ca6!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4ouC4ueC4meC4tOC5gOC4p-C4tOC4o-C5jOC4qiDguK3guK3guJ8g4Lit4Li14LmC4Lin4Lil4Li54LiK4Lix4LmI4LiZIDQyODkg4LiI4Liz4LiB4Lix4LiUIChVT0UgNDI4OSBDby4sTHRkLik!5e0!3m2!1sth!2sth!4v1682496750262!5m2!1sth!2sth"
          title="map"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </React.Fragment>
  );
}

import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Home from "./layouts/home";
import {
  AboutPage,
  BlogPage,
  ContactPage,
  CookiePage,
  HRPage,
  HomePage,
  KnowledgePage,
  PrivacyPage,
  TOSFJPage,
  TOSFSPage,
} from "./pages";

import Admin from "./layouts/admin";
import { DashboardPage, WorkPage } from "./adminpages";

import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";

import "./App.css";
import "aos/dist/aos.css";
import "moment/locale/th";

import { useTranslation } from "react-i18next";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import darkTheme from "./theme/darkTheme";
import ScrollToTop from "./comonents/scroll-to-top";

const ColorModeContext = React.createContext({ App: () => {} });

export default function App() {
  //เปลี่ยนภาษา
  useTranslation();

  //โหมดสว่างและมืด
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      App: () => {
        localStorage.setItem("theme", mode === "light" ? "dark" : "light");
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode]
  );
  React.useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", mode);
    }
  }, [mode]);
  const theme = React.useMemo(
    () =>
      createTheme(
        darkTheme(
          localStorage.getItem("theme") ? localStorage.getItem("theme") : mode
        )
      ),
    [mode]
  );

  const token = localStorage.getItem("user") ? localStorage.getItem("user") : 0;

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            <Routes>
              {/* Router หน้าเว็บ */}
              <Route
                path="/"
                element={
                  <Home
                    colorMode={colorMode}
                    ColorModeContext={ColorModeContext}
                  />
                }
              >
                <Route index={true} element={<Navigate to="/homepage" />} />
                <Route path="homepage" element={<HomePage />} />
                <Route path="hrpage" element={<HRPage />} />
                <Route path="knowledgepage" element={<KnowledgePage />} />
                <Route path="aboutpage" element={<AboutPage />} />
                <Route path="blogpage/:id" element={<BlogPage />} />
                <Route path="contactpage" element={<ContactPage />} />
                <Route path="term_service_job" element={<TOSFJPage />} />
                <Route path="term_service_seeker" element={<TOSFSPage />} />
                <Route path="privacy" element={<PrivacyPage />} />
                <Route path="cookie" element={<CookiePage />} />
              </Route>
              {/* Router หน้าเว็บ */}

              {/* Router หน้าเว็บ */}
              <Route
                path="/admin"
                element={
                  !token ? (
                    <SimpleLayout />
                  ) : (
                    <Admin
                      colorMode={colorMode}
                      ColorModeContext={ColorModeContext}
                    />
                  )
                }
              >
                <Route
                  index={true}
                  element={
                    !token ? (
                      <Navigate to="logintoadmin" />
                    ) : (
                      <Navigate to="workall" />
                    )
                  }
                />
                {!token ? (
                  <>
                    <Route path="logintoadmin" element={<LoginPage />} />
                  </>
                ) : (
                  <>
                    <Route path="dashboard" element={<DashboardPage />} />
                    <Route path="workall" element={<WorkPage />} />
                  </>
                )}
              </Route>

              {/* Router หน้าเว็บ */}

              {/* Router Layout SimpleLayout */}
              <Route element={<SimpleLayout />}>
                <Route index={true} element={<Navigate to="/" />} />
                <Route path="logintoadmin" element={<LoginPage />} />
                <Route path="404" element={<Page404 />} />
                <Route path="*" element={<Navigate to="404" />} />
                {/* <Route path="form" element={<CompanyForm />} /> */}
              </Route>
              {/* Router Layout SimpleLayout */}
              <Route path="*" element={<SimpleLayout />}>
                <Route element={<Navigate to="/404" replace />} />
              </Route>
            </Routes>
            {/* <Footer /> */}
          </ThemeProvider>
        </ColorModeContext.Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

import React, { useEffect } from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Container, Stack, Typography } from '@mui/material';
import AOS from 'aos'
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function EveryDays() {

    const App = [
        {
            id: 1,
            icon: "bx:spreadsheet",
            title: "edaytitle1",
            detail: "edaydetail1"
        },
        {
            id: 2,
            icon: "ic:baseline-home",
            title: "edaytitle2",
            detail: "edaydetail2"
        },
        {
            id: 3,
            icon: "solar:like-bold-duotone",
            title: "edaytitle3",
            detail: "edaydetail3"
        },
        {
            id: 4,
            icon: "clarity:flag-solid",
            title: "edaytitle4",
            detail: "edaydetail4"
        },
        {
            id: 5,
            icon: "zondicons:heart",
            title: "edaytitle5",
            detail: "edaydetail5"
        },
        {
            id: 6,
            icon: "bi:folder-fill",
            title: "edaytitle6",
            detail: "edaydetail6"
        },
        {
            id: 7,
            icon: "heroicons:tv",
            title: "edaytitle7",
            detail: "edaydetail7"
        },
        {
            id: 8,
            icon: "ph:map-pin-fill",
            title: "edaytitle8",
            detail: "edaydetail8"
        },
        {
            id: 9,
            icon: "iconamoon:star-light",
            title: "edaytitle9",
            detail: "edaydetail9"
        },
    ]

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <Box component="section" className='section-white'>
            <Container>
                <Stack>
                    <Stack my={4}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6} mt={3}>
                                <Typography variant='h2' fontSize="28px" fontWeight={600}><Trans i18nKey="edaytitleabout" /></Typography>
                                <Typography variant='body1' fontSize="16px" mt={3}><Trans i18nKey="edaydetailabout" /></Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Box>
                                    <Box component="img" src='/assets/images/uoe4289/home-1-rev-img-1.svg' alt='why pic' width="100%" height="100%" />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={4}>
                            {App.map((item) => {
                                return (
                                    <Grid container spacing={2} xs={12} sm={12} md={4} my={2} key={item.id} data-aos="zoom-in" data-aos-duration="3000">
                                        <Grid xs={2} sm={2} md={2}>
                                            <Box>
                                                <Iconify icon={item.icon} width="50px" />
                                            </Box>
                                        </Grid>
                                        <Grid xs={10} sm={10} md={10}>
                                            <Box>
                                                <Typography variant='body1' fontWeight={600} color="#c60c0c"><Trans i18nKey={item.title} /></Typography>
                                                <Typography variant='body1'><Trans i18nKey={item.detail} /></Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

import React from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

export default function Part5() {
    return (
        <React.Fragment>
            <Box my={1}>
                <Box my={2}>
                    <Typography className="text-bold">5. การคุ้มครองข้อมูลส่วนบุคคล และสิทธิของเจ้าของข้อมูล</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;บริษัทมีความประสงค์อย่างยิ่งที่จะรักษาความเป็นส่วนตัวและข้อมูลส่วนบุคคลของท่าน ดังนั้น บริษัทจึงขอแจ้งสิทธิของเจ้าของข้อมูล ตามที่มีอยู่และเว้นแต่ที่พึงจำกัดไว้ตามกฎหมายที่บังคับใช้ เพื่อผลประโยชน์ของท่าน ดังต่อไปนี้ตารางด้านล่าง อธิบายสิทธิที่มีต่อข้อมูลส่วนบุคคลจากพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 หมวด 3 สิทธิของเจ้าของข้อมูลส่วนบุคคล</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประเภทของสิทธิ</TableCell>
                            <TableCell className='text-normal tableprivacy'>คำอธิบายประเภทของสิทธิ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการเข้าถึง</TableCell>
                            <TableCell className='text-normal tableprivacy'>หากด้วยเหตุขัดข้องประการใดที่ทำให้ท่านไม่สามารถเข้าถึงข้อมูลส่วนบุคคลที่บริษัทประมวลผลเกี่ยวกับท่านได้ ท่านมีสิทธิในการได้รับแจ้งข้อมูลและเข้าถึงข้อมูลส่วนบุคคลของท่าน ตลอดจนขอรับสำเนาข้อมูลส่วนบุคคล หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวได้จากบริษัท</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการคัดค้าน</TableCell>
                            <TableCell className='text-normal tableprivacy'>หากท่านพิจารณาวิธีการที่บริษัทเก็บรวบรวมและนำข้อมูลส่วนบุคคลไปใช้แล้ว ตระหนักถึงผลเสียหรือผลประโยชน์ในเชิงลบใด ๆ ท่านมีสิทธิในการคัดค้านเมื่อใดก็ได้ ไม่ให้บริษัทเก็บและใช้ข้อมูลส่วนบุคคลของท่าน รวมถึงสิทธิในการคัดค้านมิให้ข้อมูลส่วนบุคคลของท่านถูกประมวลผลเพื่อวัตถุประสงค์ทางการตลาดทางตรง ซึ่งอาจส่งผลให้ท่านไม่สามารถใช้บริการทั้งหมดหรือบางส่วนในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด รวมทั้งปิดกั้นการเข้าถึงฟีเจอร์ เนื้อหาหรือบริการข้างต้นตามที่บริษัทเห็นสมควรได้</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการลบ</TableCell>
                            <TableCell className='text-normal tableprivacy'>หากด้วยเหตุขัดข้องประการใดที่ทำให้ท่านไม่สามารถลบ หรือทำลาย หรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ได้ ท่านมีสิทธิที่จะร้องขอให้บริษัทลบ หรือทำลาย หรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ โดยบริษัทจะดำเนินการลบ หรือทำลาย หรือทำให้ข้อมูลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวตน โดยบริษัทจะคงไว้ซึ่งข้อมูลดังกล่าวไว้อย่างน้อย 90 วัน และบริษัทจะดำเนินการตามคำร้องขอไม่เกิน 120 วัน นับแต่วันที่ได้รับร้องขอ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการยับยั้ง</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทอาจประมวลผลข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นเพื่อพัฒนาการให้บริการของบริษัท เพื่อประโยชน์ในการรักษาความเป็นส่วนตัวของท่าน ท่านมีสิทธิที่จะร้องขอให้บริษัทหยุดการประมวลผลข้อมูลส่วนบุคคลของท่านทั้งหมดหรือบางส่วน</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการแก้ไขให้เป็นปัจจุบัน</TableCell>
                            <TableCell className='text-normal tableprivacy'>หากท่านพบว่าข้อมูลส่วนบุคคลของท่านไม่ครบถ้วนสมบูรณ์ หรือไม่ถูกต้อง ท่านมีสิทธิที่จะขอให้บริษัทหรือปรับปรุงข้อมูลส่วนบุคคลของท่านให้เป็นปัจจุบันได้</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการถอดถอนความยินยอม</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านมีสิทธิที่จะถอดถอนความยินยอมการเก็บรวบรวม การใช้ หรือการเปิดเผยข้อมูลส่วนบุคคลของท่าน ทั้งนี้ การใช้สิทธิดังกล่าวอาจมีผลให้ท่านไม่สามารถเข้าใช้บริการได้อีกต่อไป</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>สิทธิในการให้โอนย้ายข้อมูล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านมีสิทธิที่จะร้องขอสำเนาข้อมูลส่วนบุคคลของท่านในรูปแบบอิเล็กทรอนิกส์ หรือโอนข้อมูลส่วนบุคคลของท่านเพื่อนำไปใช้กับบริการของผู้ให้บริการรายอื่นโดยท่านสามารถใช้สิทธิดังกล่าวได้โดยตรงโดยการติดต่อบริษัทตามข้อมูลการติดต่อที่ระบุไว้ด้านล่างนี้</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-normal">บริษัทยินดีที่จะเสนอความโปร่งใสในการรักษาสิทธิทางกฎหมายของเจ้าของข้อมูล หากท่านมีข้อสงสัยเกี่ยวกับความเป็นส่วนตัว หรือท่านต้องการที่จะเข้าถึง และ/หรือ แก้ไขข้อมูลส่วนบุคคลของท่าน หรือส่งข้อร้องเรียนเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านที่บริษัทดำเนินการ โปรดติดต่อศูนย์บริการของบริษัท [หัวข้อ 9 ติดต่อเรา]</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

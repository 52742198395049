import { Brightness4, Brightness7 } from '@mui/icons-material'
import { Box, IconButton, useTheme } from '@mui/material'
import React from 'react'

export default function Theme(props) {

    const { colorMode } = props
    const theme = useTheme()
    return (
        <Box mx={2} sx={{ marginTop: "8px !important" }}>
            <IconButton onClick={colorMode.App} className='buttontheme'>
                {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
        </Box>
    )
}

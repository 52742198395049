import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function Why() {
    return (
        <Box component="section" className='section-white'>
            <Container>
                <Stack my={3}>
                    <Stack mx={2} my={2}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={6}>
                                <Typography variant='h1' color="#c60c0c" fontSize="28px" fontWeight={600}><Trans i18nKey="whytitlehr" /></Typography>
                                <Typography variant='body1' color="#fa1967"><Trans i18nKey="whysubhr" /></Typography>
                                <Typography variant='body1' mt={2}><Trans i18nKey="whydetail1hr" /></Typography>
                                <Typography variant='body1' mt={2}><Trans i18nKey="whydetail2hr" /></Typography>
                                <Box mt={2}>
                                    <Button color='error' variant="contained"><Trans i18nKey="whybuttonhr" /></Button>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Box>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/nyEQTtxuBcE?si=iISMCiX0iF6ob7cJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

import React from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function Ads2() {
    return (
        <Stack>
            <Box sx={{ position: "relative", width: "100%", clear: "both", float: "left", overflow: "hidden" }}>
                <Stack mx={2}>
                    <Box sx={{ top: "auto", opacity: 1, bottom: 0, left: 0, width: "100%", zIndex: 0, height: "100%" }}>
                        <Box component="div" className="overlay" sx={{ opacity: 0.4, backgroundColor: "#000000" }}></Box>
                        <Box sx={{ backgroundImage: `url('/assets/images/uoe4289/t1-e1573121076984.svg')`, backgroundRepeat: "repeat-x", backgroundPosition: "center center", position: "absolute", width: "100%", height: "100%", bottom: 0, left: 0 }}></Box>
                    </Box>
                    <Box sx={{ position: "relative", width: "100%" }}>
                        <Container>
                            <Box className='container' my={20} mx={5}>
                                <Box sx={{ border: "none", marginLeft: "auto", paddingLeft: 0, marginRight: 0, zoom: 1, }} >
                                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                                        <Typography variant='h1' fontWeight={600} fontSize="30px" color="white" textAlign="center"><Trans i18nKey="ads2title1home" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                                        <Typography variant='h1' fontWeight={600} fontSize="30px" color="white" textAlign="center"><Trans i18nKey="ads2title2home" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "start", marginTop: 3 }}>
                                        <Typography variant='body1' fontSize="18px" color="white"><Trans i18nKey="ads2detail1home" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                                        <Typography variant='body1' fontSize="18px" color="white"><Trans i18nKey="ads2detail2home" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                                        <Typography variant='body1' fontSize="18px" color="white"><Trans i18nKey="ads2detail3home" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "start" }} mt={3}>
                                        <Button color='error' variant="contained" startIcon={<Iconify icon="material-symbols:search" />} ><Trans i18nKey="ads2buttonhome" /></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}

import React from 'react'
import {
    Box,
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
} from "@mui/material";

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: "1px",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    clip: "rect(0 0 0 0)",
};

export default function WorkHead(props) {
    const { headLabel, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className="thead" sx={{ backgroundColor: "#eef1f4" }}>
            <TableRow>
                {headLabel.map((headCell) => (
                    <TableCell
                        sx={{ fontSize: "16px", fontWeight: "400" }}
                        key={headCell.id}
                        align="left"
                        className="headtable"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" className="headtable" sx={{ ...visuallyHidden }}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function ChooseWork() {
    return (
        <Container>
            <Stack mx={3} my={3}>
                <Stack mx={2} my={6}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography variant='h1' color="#c60c0c" fontSize="28px" fontWeight={600} textAlign="center"><Trans i18nKey="chooseworktitlehome" /></Typography>
                            <Typography variant='body1'><Trans i18nKey="chooseworkdetailhome" /></Typography>
                            <Box mt={2}>
                                <Button color='error' variant="contained" startIcon={<Iconify icon="material-symbols:edit" />} ><Trans i18nKey="chooseworkbuttonhome" /></Button>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={3}>
                            <Box>
                                <Box component="img" src='/assets/images/uoe4289/t2-300x240.svg' alt='why pic' width="100%" height="100%" />
                                <Box display="flex" justifyContent="center">
                                    <Typography variant='body1' color="#c60c0c" fontSize="16px"><Trans i18nKey="choosework1home" /></Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={3}>
                            <Box>
                                <Box component="img" src='/assets/images/uoe4289/t3-300x240.svg' alt='why pic' width="100%" height="100%" />
                                <Box display="flex" justifyContent="center">
                                    <Typography variant='body1' color="#c60c0c" fontSize="16px"><Trans i18nKey="choosework2home" /></Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}

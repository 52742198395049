import React from 'react'
import { Helmet } from "react-helmet-async";
import { Work } from './work'

export default function WorkPage() {
    return (
        <React.Fragment>
            <Helmet>
                <title> งานทั้งหมด | Admin UOE4289 </title>
            </Helmet>

            <Work />
        </React.Fragment>
    )
}

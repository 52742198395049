import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function Register() {
    return (
        <Container>
            <Stack mb={3}>
                <Stack mx={2} my={2}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Box>
                                <Box component="img" src='/assets/images/uoe4289/hr-01.svg' alt='why pic' width="100%" height="100%" />
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography variant='h1' color="#c60c0c" fontSize="34px" fontWeight={600} textAlign="center"><Trans i18nKey="registertitlehr" /></Typography>
                            <Box mt={2}>
                                <Button color='error' variant="contained"><Trans i18nKey="registerbuttonhr" /></Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}

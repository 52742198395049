import React from 'react'
import {
    Box,
    Typography,
} from "@mui/material";

export default function Part9() {
    return (
        <React.Fragment>
            <Box my={1}>
                <Typography className="text-bold">9. ติดต่อเรา</Typography>
                <Typography className="text-normal">หากท่านมีข้อสงสัยหรือคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวประการใด โปรดติดต่อสอบถามได้ที่</Typography>
                <Typography className="text-normal">บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด (สำนักงานใหญ่) 9 อาคารจี ทาวเวอร์ แกรนด์ พระราม 9 ชั้น 30 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวางกรุงเทพมหานคร 10310 โทรศัพท์ 02-096-2236 กด 2 </Typography>
                <Typography className="text-normal">อีเมล mgr.hr@uoe4289.com</Typography>
            </Box>
        </React.Fragment>
    )
}

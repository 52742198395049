import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function ThreeProcess() {
    return (
        <Container>
            <Stack mx={3} my={3}>
                <Stack mx={2} my={6}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='h1' color="#c60c0c" fontSize="28px" fontWeight={600} textAlign="center"><Trans i18nKey="threeprocesstitlehome" /></Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='body1' color="#898989" fontSize="16px" textAlign="center">Simple 3 steps to your future career</Typography>
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} sm={12} md={4}>
                            <Paper>
                                <Stack mx={2} py={4}>
                                    <Typography sx={{ textAlign: "center", marginBottom: "24px" }} variant='h2' color="#c60c0c" fontSize="20px" fontWeight={600}><Trans i18nKey="1processtitlehome" /></Typography>
                                    <Typography variant='body1' color="#898989"><Trans i18nKey="1processdetailhome" /></Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid xs={12} sm={12} md={4}>
                            <Paper>
                                <Stack mx={2} py={4}>
                                    <Typography sx={{ textAlign: "center", marginBottom: "24px" }} variant='h2' color="#c60c0c" fontSize="20px" fontWeight={600}><Trans i18nKey="2processtitlehome" /></Typography>
                                    <Typography variant='body1' color="#898989"><Trans i18nKey="2processdetailhome" /></Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid xs={12} sm={12} md={4}>
                            <Paper>
                                <Stack mx={2} py={4}>
                                    <Typography sx={{ textAlign: "center", marginBottom: "24px" }} variant='h2' color="#c60c0c" fontSize="20px" fontWeight={600}><Trans i18nKey="3processtitlehome" /></Typography>
                                    <Typography variant='body1' color="#898989"><Trans i18nKey="3processdetailhome" /></Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}

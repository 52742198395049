import React, { useEffect } from "react";
import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Helmet } from "react-helmet-async";
import { WorkSmall } from "../comonents/homepage";
import moment from "moment";

export default function DetailWorkPage(props) {
  const { workData, pageId, handlePageNow } = props;
  var row = workData
    .filter((value) => value.id === parseInt(pageId))
    .map((item) => item);

  const detail = [
    {
      id: 1,
      title: "แผนก : ",
      value: `${row[0].departmentW}`,
    },
    {
      id: 2,
      title: "อัตรารับสมัคร : ",
      value: `${row[0].rate_em}`,
    },
    {
      id: 3,
      title: "ระดับการศึกษา : ",
      value: `${row[0].education}`,
    },
    {
      id: 4,
      title: "วันเวลาทำงาน : ",
      value: `${row[0].dateW} เวลา ${row[0].timeW}`,
    },
    {
      id: 5,
      title: "เงินเดือน : ",
      value: `${row[0].salaryW}`,
    },
    {
      id: 6,
      title: "ผู้ติดต่อ : ",
      value: `${row[0].companyName}`,
    },
    {
      id: 7,
      title: "อีเมล์ : ",
      value: `${row[0].email}`,
    },
    {
      id: 8,
      title: "เบอร์ติดต่อ : ",
      value: `${row[0].phone}`,
    },
    {
      id: 9,
      title: "ลงประกาศ : ",
      value: `${row[0].date}`,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title> รายละเอียดบริษัท | Admin UOE4289 </title>
      </Helmet>
      <React.Fragment>
        <Stack mx={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 20 }} mb={6}>
          <Grid container>
            <Grid xs={12} sm={12} md={6}>
              <Stack mx={2} my={3}>
                <Paper>
                  <Stack mx={2} my={3}>
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <Avatar
                        src={`${process.env.REACT_APP_FILE_API}/works/${row[0].companyName}/${row[0].logo}`}
                        alt="Company Logo"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Box>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={9} md={9}>
                        <Box mt={2}>
                          <Typography
                            variant="body1"
                            fontSize="20px"
                            fontWeight={600}
                          >
                            {row[0].companyName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body1" fontSize="16px">
                            อีเมล์ : {row[0].email}
                          </Typography>
                          <Typography variant="body1" fontSize="16px">
                            โทรศัพท์ : {row[0].phone}
                          </Typography>
                          <Typography variant="body1" fontSize="16px">
                            ประเภท : {row[0].companyType}
                          </Typography>
                          <Typography variant="body1" fontSize="16px">
                            วันที่ลง :{" "}
                            {moment(row[0].date, "MM/DD/YYYY : HH:mm:ss")
                              .locale("th")
                              .format("LLLL")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Typography variant="body1" fontSize="16px">
                        &emsp;{row[0].detailCompany}
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="body1" fontSize="16px">
                          ตำบล : {row[0].subdist}
                        </Typography>
                        <Typography variant="body1" fontSize="16px">
                          อำเภอ : {row[0].dist}
                        </Typography>
                        <Typography variant="body1" fontSize="16px">
                          จังหวัด : {row[0].province}
                        </Typography>
                        <Typography variant="body1" fontSize="16px">
                          รหัสไปรษณีย์ : {row[0].zipcode}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>

              <Stack mx={2} my={3}>
                <Paper>
                  <Stack mx={2} my={3}>
                    <Typography variant="body1" fontSize="16px">
                      ตำแหน่งงาน
                    </Typography>
                    <Typography variant="body1" fontSize="24px" color="#c60c0c">
                      {" "}
                      {row[0].positionW}{" "}
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body1" fontSize="16px">
                        รายละเอียด
                      </Typography>
                      <Typography variant="body1" fontSize="16px">
                        &emsp;{row[0].detailPosition}
                      </Typography>
                    </Box>
                    <Grid container mt={3}>
                      {detail.map((item) => {
                        return (
                          <React.Fragment key={item.id}>
                            <Grid xs={4} sm={4} md={4}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  fontSize="16px"
                                  fontWeight={600}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid xs={8} sm={8} md={8}>
                              <Box>
                                <Typography variant="body1" fontSize="16px">
                                  {item.value}
                                </Typography>
                              </Box>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Paper>
              </Stack>

              <Stack mx={2} my={3}>
                <Paper>
                  <Stack mx={2} my={3}>
                    <Typography variant="body1" fontSize="24px" color="#c60c0c">
                      สวัสดิการ
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body1" fontSize="16px">
                        รายละเอียด
                      </Typography>
                      <Typography variant="body1" fontSize="16px">
                        &emsp;{row[0].detailWelfare}
                      </Typography>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>

              <Stack mx={2} my={3}>
                <Paper>
                  <Stack mx={2} my={3}>
                    <Typography variant="body1" fontSize="24px" color="#c60c0c">
                      สนใจที่จะสมัครงานตำแหน่งนี้
                    </Typography>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="error"
                        id="resumework"
                        onClick={(e) => handlePageNow(e, row[0].id)}
                      >
                        สมัครงาน
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <WorkSmall
                workData={workData}
                row={row}
                handlePageNow={handlePageNow}
              />
            </Grid>
          </Grid>
        </Stack>
      </React.Fragment>
    </>
  );
}

import React from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

export default function Part2() {
    return (
        <React.Fragment>
            <Box my={1}>
                <Typography className="text-bold">2.ข้อมูลส่วนบุคคลที่เก็บรวบรวม</Typography>
                <Typography className="text-normal">&nbsp;&nbsp;บริษัทได้กำหนดประเภทของข้อมูลส่วนบุคคลที่จะเก็บรวบรวมและใช้ รวมถึงวิธีการเก็บข้อมูลไว้ในตารางด้านล่างนี้</Typography>
                <ul>
                    <Typography className="text-bold">2.1 ข้อมูลส่วนบุคคลของผู้หางาน</Typography>
                    <li className='text-normal'>ข้อมูลบัญชีผู้ใช้งาน</li>
                    <li className='text-normal'>ข้อมูลประวัติและใบสมัครงาน</li>
                    <li className='text-normal'>ข้อมูลการใช้บริการ</li>
                </ul>
                <Typography className="text-normal">ตารางด้านล่างอธิบายข้อมูลบัญชีผู้ใช้งานของผู้หางาน</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow >
                            <TableCell className="text-normal tableprivacy">ประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className="text-normal tableprivacy">คำอธิบายประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className="text-normal tableprivacy">ระยะเวลาในการเก็บข้อมูล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow>
                            <TableCell className="text-normal tableprivacy">บัญชีผู้ใช้งาน</TableCell>
                            <TableCell className="text-normal tableprivacy">ข้อมูลส่วนบุคคลของท่านที่อยู่ในบัญชีผู้ใช้งาน มีดังนี้</TableCell>
                            <TableCell className="text-normal tableprivacy">บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็น</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell className='text-normal tableprivacy'></TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>อีเมล</li>
                                    <li className='text-normal'>รหัสผ่าน</li>
                                </ul>
                                โดยบริษัทจะเก็บข้อมูลรหัสผ่านในรูปแบบการเข้ารหัสเพื่อความปลอดภัยของบัญชีผู้ใช้งาน
                            </TableCell>
                            <TableCell className='text-normal tableprivacy'>ผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) หลังจากวันที่ท่านลบบัญชีผู้ใช้งานออกจากระบบ บริษัทจะยังคงเก็บข้อมูลดังกล่าวไว้เป็นเวลาอย่างน้อย 90 วัน แต่ไม่เกิน 120 วัน นับแต่วันที่ได้ลบบัญชีผู้ใช้งานออกจากระบบ</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-normal">ตารางด้านล่างอธิบายข้อมูลประวัติและใบสมัครงานของผู้หางาน</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>คำอธิบายประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ระยะเวลาในการเก็บข้อมูล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                ข้อมูลส่วนบุคคลของท่านที่อยู่ในแบบฟอร์มประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด มีดังนี้
                                <ul>
                                    <li className='text-normal'>รูปถ่าย</li>
                                    <li className='text-normal'>ชื่อ-นามสกุล</li>
                                    <li className='text-normal'>วัน/เดือน/ปีเกิด</li>
                                    <li className='text-normal'>เพศ ส่วนสูง น้ำหนัก</li>
                                    <li className='text-normal'>สถานภาพ สัญชาติ ศาสนา</li>
                                    <li className='text-normal'>เบอร์โทรศัพท์ อีเมล ไลน์ไอดี ที่อยู่</li>
                                    <li className='text-normal'>ข้อมูลความพิการ</li>
                                    <li className='text-normal'>ความสามารถ ผลงาน</li>
                                    <li className='text-normal'>ประวัติการศึกษา ประวัติการทำงาน</li>
                                    <li className='text-normal'>ลักษณะงานที่ต้องการ</li>
                                    <li className='text-normal'>บุคคลอ้างอิง เป็นต้น</li>
                                </ul>
                                หลังจากสมัครเป็นสมาชิกและบันทึกประวัติในแบบฟอร์มที่กำหนดเพื่อใช้ในการสมัครงานแล้ว ท่านสามารถปรับเปลี่ยนสถานะของประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ได้ดังต่อไปนี้
                                <ul>
                                    <li className='text-normal'>ถูกค้นเจอได้โดยผู้ประกอบการ</li>
                                    <li className='text-normal'>ถูกค้นเจอได้โดยผู้ประกอบการ แต่ปิดบังข้อมูลส่วนบุคคลบางส่วน</li>
                                    <li className='text-normal'>ปิดบังประวัติไม่ให้ถูกค้นเจอในรายการการค้นหาโดยผู้ประกอบการ</li>
                                    <li className='text-normal'>ลบประวัติออกจากฐานข้อมูล</li>
                                </ul>
                                โดยบริษัทมีบริการตรวจสอบข้อมูลประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ที่ถูกบันทึกและจัดเก็บไว้ในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และอาจแจ้งข้อบกพร่องหรือความไม่สมบูรณ์ของข้อมูลให้แก่ท่านผ่านอีเมล หรือ เบอร์โทรศัพท์ หรือ ช่องทางการติดต่ออื่น ๆ ที่ท่านให้ไว้ ก่อนส่งให้แก่ผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ เพื่อภาพลักษณ์ที่ดีของผู้หางาน
                            </TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) หลังจากวันที่ท่านลบบัญชีผู้ใช้งานออกจากระบบ บริษัทจะยังคงเก็บข้อมูลดังกล่าวไว้เป็นเวลาอย่างน้อย 90 วัน แต่ไม่เกิน 120 วัน นับแต่วันที่ได้ลบบัญชีผู้ใช้งานออกจากระบบ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ใบสมัครจากวิธีการสมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านสามารถสมัครงานโดยส่งข้อมูลใบสมัครที่ถูกบันทึกและจัดเก็บไว้ในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ให้แก่ผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ</TableCell>
                            <TableCell className='text-normal tableprivacy' rowSpan={4}>บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) บริษัทจะดำเนินการลบ หรือทำลาย หรือทำให้ข้อมูลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวตน โดยบริษัทจะคงไว้ซึ่งข้อมูลดังกล่าวไว้อย่างน้อย 90 วัน และบริษัทจะดำเนินการตามคำร้องขอไม่เกิน 120 วัน นับแต่วันที่ได้รับร้องขอ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ใบสมัครจากวิธีการส่งไฟล์ประวัติ</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านสามารถสมัครงานโดยส่งไฟล์เอกสาร เช่น เรซูเม่ (Resume) ใบแสดงผลการศึกษา เอกสารแสดงผลงาน สำเนาทะเบียนบ้าน สำเนาบัตรประชาชนหรือเอกสารประกอบการสมัครงานอื่น ๆ เป็นต้น บนระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด โดยบริษัทมีบริการตรวจสอบเอกสารประกอบการสมัครงานที่ถูกบันทึกและจัดเก็บไว้ในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และอาจแจ้งข้อบกพร่องหรือความไม่สมบูรณ์ของข้อมูลให้แก่ท่านผ่านอีเมล หรือ เบอร์โทรศัพท์ หรือ ช่องทางการติดต่ออื่น ๆ ที่ท่านให้ไว้ ก่อนส่งให้แก่ผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ เพื่อภาพลักษณ์ที่ดีของผู้หางาน</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ใบสมัครจากวิธีการส่งอีเมล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านสามารถสมัครงานโดยส่งข้อความหรือไฟล์เอกสารประกอบการสมัครงานผ่านทางอีเมลไปหาผู้ประกอบการได้โดยบริษัทมีบริการตรวจสอบข้อมูลอีเมลและเอกสารประกอบการสมัครงาน เพื่อคัดกรองอีเมลสแปมและอีเมลโฆษณาก่อนส่งต่อให้กับผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ใบสมัครจากวิธีการ
                                กรอกประวัติแบบย่อ
                            </TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                เพื่ออำนวยความสะดวกในการสมัครงานแก่ท่าน ท่านสามารถสมัครงานโดยบันทึกประวัติแบบย่อในแบบฟอร์มที่กำหนด โดยข้อมูลส่วนบุคคลภายในแบบฟอร์มประวัติแบบย่อ มีดังนี้
                                <ul>
                                    <li className='text-normal'>รูปถ่าย</li>
                                    <li className='text-normal'>ชื่อ-นามสกุล</li>
                                    <li className='text-normal'>วัน/เดือน/ปี</li>
                                    <li className='text-normal'>เพศ</li>
                                    <li className='text-normal'>เบอร์โทรศัพท์ อีเมล ไลน์ไอดี ที่อยู่</li>
                                    <li className='text-normal'>ประวัติการศึกษา ประวัติการทำงาน</li>
                                    <li className='text-normal'>เอกสารแนบประกอบการสมัครงานอื่นๆ เป็นต้น</li>
                                </ul>
                                โดยบริษัทมีบริการตรวจสอบข้อมูลประวัติแบบย่อ ที่ถูกบันทึกและจัดเก็บไว้ในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และอาจแจ้งข้อบกพร่องหรือความไม่สมบูรณ์ของข้อมูลให้แก่ท่านผ่านอีเมล หรือ เบอร์โทรศัพท์ หรือ ช่องทางการติดต่ออื่น ๆ ที่ท่านให้ไว้ ก่อนส่งให้แก่ผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ เพื่อภาพลักษณ์ที่ดีของผู้หางาน
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-normal">ตารางด้านล่างอธิบายข้อมูลการใช้บริการของผู้หางาน</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>คำอธิบายประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ระยะเวลาในการเก็บข้อมูล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประวัติการสมัครงาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะจัดเก็บข้อมูลเกี่ยวกับการสมัครงานของท่านเพื่อสร้างประวัติการสมัครงานย้อนหลัง</TableCell>
                            <TableCell className='text-normal tableprivacy' rowSpan={2}>บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) หลังจากลบบัญชีผู้ใช้งานออกจากระบบ บริษัทจะยังคงเก็บข้อมูลดังกล่าวไว้เป็นเวลาอย่างน้อย 90 วัน แต่ไม่เกิน 120 วัน นับแต่วันที่ได้ลบบัญชีผู้ใช้งานออกจากระบบ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>งานที่สนใจ</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะจัดเก็บข้อมูลเกี่ยวกับงานที่สนใจของท่านเพื่ออำนวยความสะดวกให้ท่านสามารถย้อนดูงานที่ท่านบันทึกความสนใจได้</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>การค้นหาตำแหน่งงาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                บริษัทจะจัดเก็บข้อมูลเกี่ยวกับการค้นหางานบนระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ของท่าน เพื่อนำมาประมวลผลและพัฒนาการค้นหาตำแหน่งงานให้ตอบโจทย์ความต้องการของท่านมากยิ่งขึ้น ข้อมูลการค้นหางานประกอบด้วยข้อมูลที่ท่านกรอกลงในแบบฟอร์มการค้นหา ดังต่อไปนี้
                                <ul>
                                    <li className='text-normal'>คำค้นหา</li>
                                    <li className='text-normal'>สถานที่ปฏิบัติงาน</li>
                                    <li className='text-normal'>ประเภทงานหลัก ประเภทงานย่อย ประเภทธุรกิจ</li>
                                    <li className='text-normal'>เงินเดือนที่ต้องการ</li>
                                    <li className='text-normal'>พิกัดตำแหน่ง (ของเครื่องมือสื่อสาร)</li>
                                    <li className='text-normal'>อันดับของตำแหน่งงานที่ท่านเลือกเข้าชมในผลลัพธ์การค้นหาเป็นต้น</li>
                                </ul>
                            </TableCell>
                            <TableCell className='text-normal tableprivacy'>1 ปี</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ข้อมูลการใช้งานเว็บไซต์ และ/หรือ แอปพลิเคชัน บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทอาจจัดเก็บข้อมูลการเข้าถึงหรือวิธีที่ท่านใช้งานเว็บไซต์ และ/หรือ แอปพลิเคชันต่าง ๆ ของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ผ่านผู้ให้บริการวิเคราะห์ข้อมูลบุคคลที่สาม</TableCell>
                            <TableCell className='text-normal tableprivacy'>ในกรณีที่มีการจัดเก็บข้อมูลการใชังานเว็บไซต์ และ/หรือ แอปพลิเคชันต่าง ๆ ของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ผ่านผู้ให้บริการวิเคราะห์ข้อมูลบุคคลที่สาม บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดของท่านในรูปแบบของข้อมูลที่ไม่สามารถระบุตัวตนตามความเหมาะสม</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เสียงสนทนาระหว่างผู้หางานกับ Call Center</TableCell>
                            <TableCell className='text-normal tableprivacy'>เมื่อท่านติดต่อบริษัทผ่านทาง Call Center บริษัทอาจบันทึกเสียงสนทนาของท่านเพื่อการปรับปรุงพัฒนาคุณภาพการให้บริการของบริษัท</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะเก็บรักษาข้อมูลดังกล่าวไว้ในระบบ 90 วัน นับแต่ขณะมีการสนทนา หรือนานกว่านั้น ในกรณีที่บริษัทฯเห็นสมควร</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-bold">2.2 ข้อมูลส่วนบุคคลของผู้ประกอบการ</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;ตารางด้านล่างอธิบายข้อมูลส่วนบุคคลของผู้ประกอบการ</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>คำอธิบายประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ระยะเวลาในการเก็บข้อมูล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>บัญชีผู้ใช้งาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                ข้อมูลส่วนบุคคลของท่านที่เกี่ยวกับบัญชีผู้ใช้งาน มีดังนี้
                                <ul>
                                    <li className='text-normal'>ชื่อผู้ใช้</li>
                                    <li className='text-normal'>รหัสผ่าน</li>
                                </ul>
                                โดยบริษัทจะเก็บข้อมูลรหัสผ่านในรูปแบบการเข้ารหัสเพื่อความปลอดภัยของบัญชีผู้ใช้งาน
                            </TableCell>
                            <TableCell className='text-normal tableprivacy' rowSpan={3}>บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) หลังจากลบบัญชีผู้ใช้งานออกจากระบบ บริษัทจะยังคงเก็บข้อมูลดังกล่าวไว้เป็นเวลาอย่างน้อย 90 วัน แต่ไม่เกิน 120 วัน นับแต่วันที่ได้ลบบัญชีผู้ใช้งานออกจากระบบ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ข้อมูลติดต่อของผู้ประกอบการเพื่อการรับสมัครงาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                ข้อมูลติดต่อของผู้ประกอบการที่ลงประกาศในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด เพื่อรับสมัครงานอาจเป็นข้อมูลส่วนบุคคลของพนักงาน เจ้าหน้าที่ หรือบุคคลที่เกี่ยวข้องของผู้ประกอบการ อันประกอบด้วยข้อมูลดังต่อไปนี้
                                <ul>
                                    <li className='text-normal'>ชื่อ-นามสกุล</li>
                                    <li className='text-normal'>ที่อยู่ เบอร์โทรศัพท์ อีเมล ไลน์ไอดี เป็นต้น</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ข้อมูลติดต่อของผู้ประกอบการสำหรับการใช้บริการบนระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                ข้อมูลติดต่อของผู้ประกอบการที่ได้ส่งให้แก่บริษัท เพื่อการใช้บริการบนระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด โดยเฉพาะอาจเป็นข้อมูลส่วนบุคคลของพนักงาน เจ้าหน้าที่ หรือบุคคลที่เกี่ยวข้องของผู้ประกอบการ อันประกอบด้วยข้อมูลดังต่อไปนี้
                                <ul>
                                    <li className='text-normal'>ชื่อ-นามสกุล</li>
                                    <li className='text-normal'>ที่อยู่ เบอร์โทรศัพท์ อีเมล ไลน์ไอดี เป็นต้น</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ข้อมูลประกอบการลงโฆษณา</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                ข้อมูลที่ผู้ประกอบการส่งให้แก่บริษัท เพื่อประกอบการลงโฆษณาประกาศรับสมัครงาน หรือโฆษณาในรูปแบบอื่น ซึ่งอาจมีข้อมูลส่วนบุคคลของบุคคลใดบุคคลหนึ่ง ในรูปแบบดังนี้
                                <ul>
                                    <li className='text-normal'>รูปภาพส่วนบุคคล</li>
                                    <li className='text-normal'>วิดีโอ</li>
                                    <li className='text-normal'>ไฟล์เสียง</li>
                                    <li className='text-normal'>ข้อความ เป็นต้น</li>
                                </ul>
                            </TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะจัดเก็บบรรดาข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ให้แก่บริษัท (1) ตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้บริการของระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด และ (2) หลังจากลบบัญชีผู้ใช้งานออกจากระบบ บริษัทจะยังคงเก็บข้อมูลดังกล่าวไว้เป็นเวลาอย่างน้อย 90 วัน แต่ไม่เกิน 120 วัน นับแต่วันที่ได้ลบบัญชีผู้ใช้งานออกจากระบบ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เสียงสนทนาระหว่างผู้ประกอบการกับ Call Center</TableCell>
                            <TableCell className='text-normal tableprivacy'>เมื่อท่านติดต่อบริษัทผ่านทาง Call Center บริษัทอาจบันทึกเสียงสนทนาของท่านเพื่อการปรับปรุงพัฒนาภาพการให้บริการของบริษัท</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะเก็บรักษาข้อมูลดังกล่าวไว้ในระบบ 90 วัน นับแต่ขณะมีการสนทนา หรือนานกว่านั้น ในกรณีที่บริษัทฯเห็นสมควร</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-bold">2.3 ข้อมูลส่วนบุคคลของผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;ตารางด้านล่างอธิบายข้อมูลส่วนบุคคลของผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>ประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>คำอธิบายประเภทของข้อมูลส่วนบุคคล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ระยะเวลาในการเก็บข้อมูล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>อีเมลของผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</TableCell>
                            <TableCell className='text-normal tableprivacy'>ท่านสามารถกรอกข้อมูลอีเมลเพื่อติดตามข่าวสาร บทความแนะนำ หรือข้อมูลที่เป็นประโยชน์อื่น ๆ ของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด ได้</TableCell>
                            <TableCell className='text-normal tableprivacy'>บริษัทจะจัดเก็บอีเมลของท่านไว้จนกว่าท่านจะกดยกเลิกการติดตาม (unsubscribe) blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-normal">&nbsp;&nbsp;ทั้งนี้ บริษัทจะจัดเก็บข้อมูลส่วนบุคคลของท่านเฉพาะข้อมูลที่จำเป็นเพื่อประโยชน์ในการใช้ข้อมูลส่วนบุคคลตามที่ระบุไว้ในข้อ 3 เท่านั้น อนึ่ง บริษัทจะไม่จัดเก็บข้อมูลส่วนบุคคลของท่านซึ่งเกี่ยวกับศาสนา ลักษณะความพิการ พฤติกรรมทางเพศ หรือข้อมูลที่อาจก่อให้เกิดความรู้สึกเกี่ยวกับการเลือกปฏิบัติโดยไม่เป็นธรรมหรือไม่เท่าเทียมกัน เว้นแต่</Typography>
                    <Typography className="text-normal"><Typography component="span" className="text-bold">1.</Typography> ได้รับความยินยอมโดยชัดแจ้งจากเจ้าของข้อมูล</Typography>
                    <Typography className="text-normal"><Typography component="span" className="text-bold">2.</Typography> ข้อมูลส่วนบุคคลที่เก็บรวบรวม</Typography>
                    <Typography className="text-normal"><Typography component="span" className="text-bold">3.</Typography>	เป็นกรณีที่กฎหมายกำหนด</Typography>
                    <Typography className="text-normal"><Typography component="span" className="text-bold">4.</Typography>	เพื่อประโยชน์ในการศึกษา วิจัย หรือการจัดทำสถิติ</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Trans } from "react-i18next";

export default function ContactPay() {
  return (
    <Box component="section" className="section-white">
      <Container>
        <Stack mb={10}>
          <Stack my={5}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h2"
                fontSize="34px"
                fontWeight={600}
                textAlign="center"
              >
                <Trans i18nKey="paytitlecontact" />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" fontSize="16px">
                <Trans i18nKey="paysubcontact" />
              </Typography>
            </Box>
            <Grid container spacing={2} mt={5}>
              <Grid xs={12} sm={12} md={6}>
                <Stack>
                  <Paper elevation={3}>
                    <Stack mx={2} my={6}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          variant="h5"
                          fontWeight={600}
                          fontSize="24px"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymenttitlecontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymentaccontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymentancontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymentbankcontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymentbranchcontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="paymenttypecontact" />
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Stack>
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <Stack>
                  <Paper elevation={3}>
                    <Stack mx={2} my={6}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          variant="h5"
                          fontWeight={600}
                          fontSize="24px"
                          textAlign="center"
                        >
                          <Trans i18nKey="notetitlecontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="notedetailcontact" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize="16px"
                          color="#c60c0c"
                          textAlign="center"
                        >
                          <Trans i18nKey="noteemailcontact" />
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

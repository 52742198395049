import React from "react";
import { NavLink as RouterLink, Link } from "react-router-dom";
// import PropTypes from 'prop-types';
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Drawer,
  List,
  ListItemText,
  Container,
  Divider,
} from "@mui/material";

//
import { StyledNavItem } from "./styles";

import SideBarSeaction from "./SideBarSeaction";
import SideBarSeactionRed from "./SideBarSeactionRed";
import { Trans } from "react-i18next";
import Language from "./Language";
import Theme from "./Theme";
import AccountPopover from "./AccountPopover";

// import { useMediaQuery } from "react-responsive";

//
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

// const NAV_WIDTH = 280;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
}));

export default function Header({
  onOpenNav,
  user,
  colorMode,
  ColorModeContext,
}) {
  //   const isMobile = useMediaQuery({ maxWidth: 1217 });

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (left, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  const list = (left) => (
    <Box sx={{ display: { lg: "none" } }}>
      <Box
        sx={{ width: 200, height: 350 }}
        role="presentation"
        onClick={toggleDrawer(left, false)}
        onKeyDown={toggleDrawer(left, false)}
      >
        <Link to="/">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              src="/assets/images/uoe4289/logo.svg"
              alt="logo"
              sx={{ height: "88px" }}
            />
          </Box>
        </Link>

        <Box my={3}>
          <Divider />
        </Box>
        <List disablePadding sx={{ p: 1, height: "57.22px" }}>
          <StyledNavItem
            component={RouterLink}
            to="/hrpage"
            sx={{
              fontSize: "20px",
              "&.active": {
                bgcolor: "action.selected",
                fontWeight: 600,
              },
            }}
          >
            <ListItemText
              disableTypography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Trans i18nKey="humanresources" />
            </ListItemText>
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/knowledgepage"
            sx={{
              fontSize: "20px",
              "&.active": {
                bgcolor: "action.selected",
                fontWeight: 600,
              },
            }}
          >
            <ListItemText
              disableTypography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Trans i18nKey="knowledge" />
            </ListItemText>
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/aboutpage"
            sx={{
              fontSize: "20px",
              "&.active": {
                bgcolor: "action.selected",
                fontWeight: 600,
              },
            }}
          >
            <ListItemText
              disableTypography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Trans i18nKey="about" />
            </ListItemText>
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/contactpage"
            sx={{
              fontSize: "20px",
              "&.active": {
                bgcolor: "action.selected",
                fontWeight: 600,
              },
            }}
          >
            <ListItemText
              disableTypography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Trans i18nKey="contact" />
            </ListItemText>
          </StyledNavItem>
        </List>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Language />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Theme colorMode={colorMode} ColorModeContext={ColorModeContext} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {localStorage.getItem("user") ? <AccountPopover /> : <></>}
      </Box>
    </Box>
  );

  return (
    <>
      <StyledRoot className="nav-bar">
        <SideBarSeactionRed />
        <Container>
          <SideBarSeaction
            state={state}
            setState={setState}
            colorMode={colorMode}
            ColorModeContext={ColorModeContext}
          />
        </Container>
      </StyledRoot>
      {state.left === true ? (
        <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
          {list("left")}
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}

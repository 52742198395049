import { Box, Card, CardContent, CardMedia, Container, Divider, Stack, Typography } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TumblrIcon, TumblrShareButton, TwitterIcon, TwitterShareButton, VKIcon, VKShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Blog from '../fake/blog.json'
import moment from 'moment/moment';
import i18next from 'i18next';

export default function BlogPage() {

    const { id } = useParams();

    return (
        <Stack>
            <Container>
                <Stack>
                    <Box my={4}>
                        <Typography fontSize={34} fontWeight={600}>Blog - บทความ</Typography>
                    </Box>
                    {Blog.filter(value => value.id === parseInt(id)).map((item) => {
                        return (
                            <Card sx={{ marginBottom: "20px", }} key={item.id}>
                                <Stack mx={2} my={2}>
                                    <CardMedia className='cardimage' component='img' src={item.img} alt='44' height="auto" width="100%" />
                                    <CardContent>
                                        {item.detail}
                                    </CardContent>
                                    <Divider />
                                    <CardContent>{moment(item.footer, "MM/DD/YYYY : HH:mm:ss", i18next.language).format('LLLL')}</CardContent>
                                </Stack>
                            </Card>
                        )
                    })}
                    <Box>
                        <FacebookShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>

                        <TwitterShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>

                        <WhatsappShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>

                        <PinterestShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <PinterestIcon size={32} round={true} />
                        </PinterestShareButton>

                        <LinkedinShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>

                        <TumblrShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <TumblrIcon size={32} round={true} />
                        </TumblrShareButton>

                        <VKShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <VKIcon size={32} round={true} />
                        </VKShareButton>

                        <RedditShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <RedditIcon size={32} round={true} />
                        </RedditShareButton>

                        <EmailShareButton url={`https://www.uoe4289.com/2020/09/07/เป็นผลงานภาพเคลื่อนไหว-2/`} style={{ marginRight: 20 }}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </Box>
                </Stack>
            </Container>
        </Stack>
    )
}

import { Box, Typography, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Iconify from '../../../comonents/iconify';
import React from 'react'

function Copyright() {
    return (
        <Box>
            <Typography variant="body2" color="white">
                {'© Copyright '}
                - uoe4289.com | หางาน | หาคน | งานทรัพยากรบุคคล
            </Typography>
        </Box>
    );
}

function IconContact() {
    return (
        <Box>
            <Box component="a" href='https://www.facebook.com/profile.php?id=100089135664630'>
                <Iconify icon='uil:facebook' color='white' />
            </Box>
            <Box component="a">
                <Iconify icon='ant-design:instagram-filled' color='white' />
            </Box>
            <Box component="a">
                <Iconify icon='entypo-social:youtube' color='white' />
            </Box>
        </Box>
    )
}

const defaultTheme = createTheme();

export default function Footer() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                className='nav'
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: "#222222"
                }}>
                <Container maxWidth="lg">
                    <Box my={6} ml={4}>
                        <Grid container spacing={2}>
                            <Grid sm={12} md={3} color="white">
                                <Box>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: "1.1em", fontWeight: 600 }}>Why uoe4289 ?</Typography>
                                </Box>
                                <Box>
                                    <Typography>การสมัครงาน หรือ มองหาคนทำงานในตำแหน่งต่างๆ ผ่าน UOE4289 ของเรามีข้อดีหลายอย่าง ที่สำคัญ ง่าย สะดวก ข้อมูลต่างๆ ที่แลกเปลี่ยนกันระหว่างบริษัทผู้ที่ต้องการหาคนในตำแหน่งงานต่างๆ…</Typography>
                                </Box>
                            </Grid>
                            <Grid sm={12} md={3} color="white">
                                <Box>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: "1.1em", fontWeight: 600 }}>OUR OFFICE UOE4289</Typography>
                                </Box>
                                <Box>
                                    <Typography>บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด อาคารจี ทาวเวอร์ แกรนด์ พระราม 9  ชั้น 30 (Common Ground)  เลขที่ 9 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310</Typography>
                                </Box>
                                <Box>
                                    <Typography>Tel: <Box component="a" href='tel:+66801935295' color="white">+66 080-193-5295</Box></Typography>
                                </Box>
                            </Grid>
                            <Grid sm={12} md={3} color="white">
                                <Box>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: "1.1em", fontWeight: 600 }}>SERVICE UOE4289</Typography>
                                </Box>
                                <Box>
                                    <Typography><Box component="a" href="resumepage" color="white">Application Form Register Resume</Box></Typography>
                                </Box>
                                <Box>
                                    <Typography><Box component="a" href={void (0)} color="white">ฝากประวัติ / ส่งใบสมัครงานกับเรา</Box></Typography>
                                </Box>
                                <Box>
                                    <Typography><Box component="a" href={void (0)} color="white">รายได้ – ตำแหน่งงาน</Box></Typography>
                                </Box>
                            </Grid>
                            <Grid sm={12} md={3} color="white">
                                <Box>
                                    <Typography sx={{ textTransform: "uppercase", fontSize: "1.1em", fontWeight: 600 }}>ABOUT UOE4289</Typography>
                                </Box>
                                <Box>
                                    <Typography><Box component="a" href="aboutpage" color="white">About UOE4289</Box></Typography>
                                </Box>
                                <Box>
                                    <Typography><Box component="a" href="contactpage" color="white">ติดต่อเรา</Box></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: { sm: 'space-between' } }} ml={4}>
                        <Copyright />
                        <IconContact />
                    </Box>
                </Container>
            </Box >
        </ThemeProvider >
    )
}

import React from "react";
import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Helmet } from "react-helmet-async";
import moment from "moment";

export default function ViewWork(props) {
  const { row, handlePageNow } = props;

  const detail = [
    {
      id: 1,
      title: "แผนก : ",
      value: `${row.departmentW}`,
    },
    {
      id: 2,
      title: "อัตรารับสมัคร : ",
      value: `${row.rate_em}`,
    },
    {
      id: 3,
      title: "ระดับการศึกษา : ",
      value: `${row.education}`,
    },
    {
      id: 4,
      title: "วันเวลาทำงาน : ",
      value: `${row.dateW} เวลา ${row.timeW}`,
    },
    {
      id: 5,
      title: "เงินเดือน : ",
      value: `${row.salaryW}`,
    },
    {
      id: 6,
      title: "ผู้ติดต่อ : ",
      value: `${row.companyName}`,
    },
    {
      id: 7,
      title: "อีเมล์ : ",
      value: `${row.email}`,
    },
    {
      id: 8,
      title: "เบอร์ติดต่อ : ",
      value: `${row.phone}`,
    },
    {
      id: 9,
      title: "ลงประกาศ : ",
      value: `${row.date}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title> รายละเอียดบริษัท | Admin UOE4289 </title>
      </Helmet>
      <React.Fragment key={row.id}>
        <Stack mx={{ xs: 0, sm: 0, md: 0, lg: 10 }} mb={6}>
          <Stack mb={2}>
            <Paper>
              <Stack mx={2} my={3}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Avatar
                    src={`${process.env.REACT_APP_FILE_API}/works/${row.companyName}/${row.logo}`}
                    alt="Company Logo"
                    sx={{ width: 56, height: 56 }}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    id="editwork"
                    onClick={(e) => handlePageNow(e, row.id)}
                  >
                    แก้ไข
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={10} md={10}>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        fontSize="20px"
                        fontWeight={600}
                      >
                        {row.companyName}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" fontSize="16px">
                        อีเมล์ : {row.email}
                      </Typography>
                      <Typography variant="body1" fontSize="16px">
                        โทรศัพท์ : {row.phone}
                      </Typography>
                      <Typography variant="body1" fontSize="16px">
                        ประเภท : {row.companyType}
                      </Typography>
                      <Typography variant="body1" fontSize="16px">
                        วันที่ลง :{" "}
                        {moment(row.date, "MM/DD/YYYY : HH:mm:ss")
                          .locale("th")
                          .format("LLLL")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Typography variant="body1" fontSize="16px">
                    &emsp;{row.detailCompany}
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1" fontSize="16px">
                      ตำบล : {row.subdist}
                    </Typography>
                    <Typography variant="body1" fontSize="16px">
                      อำเภอ : {row.dist}
                    </Typography>
                    <Typography variant="body1" fontSize="16px">
                      จังหวัด : {row.province}
                    </Typography>
                    <Typography variant="body1" fontSize="16px">
                      รหัสไปรษณีย์ : {row.zipcode}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Paper>
          </Stack>

          <Stack mb={2}>
            <Paper>
              <Stack mx={2} mb={3}>
                <Typography variant="body1" fontSize="16px">
                  ตำแหน่งงาน
                </Typography>
                <Typography variant="body1" fontSize="24px" color="#c60c0c">
                  {" "}
                  {row.positionW}{" "}
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1" fontSize="16px">
                    รายละเอียด
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
                    &emsp;{row.detailPosition}
                  </Typography>
                </Box>
                <Grid container mt={3}>
                  {detail.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Grid xs={4} sm={2} md={2}>
                          <Box>
                            <Typography
                              variant="body1"
                              fontSize="16px"
                              fontWeight={600}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={8} sm={10} md={10}>
                          <Box>
                            <Typography variant="body1" fontSize="16px">
                              {item.value}
                            </Typography>
                          </Box>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Stack>
            </Paper>
          </Stack>

          <Stack mb={2}>
            <Paper>
              <Stack mx={2} mb={3}>
                <Typography variant="body1" fontSize="24px" color="#c60c0c">
                  สวัสดิการ
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1" fontSize="16px">
                    รายละเอียด
                  </Typography>
                  <Typography variant="body1" fontSize="16px">
                    &emsp;{row.detailWelfare}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </React.Fragment>
    </>
  );
}

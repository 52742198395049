import React from 'react'
import { Helmet } from "react-helmet-async";
import { Ads1, Ads2, Candidates, Package, Register, SearchRegister, Why } from '../comonents/humanresources'; //Comments,

export default function HRPage() {
    return (
        <>
            <Helmet>
                <title> ทรัพยากรบุคคล | UOE4289 </title>
            </Helmet>

            <Candidates />
            <Register />
            <Why />
            {/* <Comments /> */}
            <SearchRegister />
            <Ads1 />
            <Package />
            <Ads2 />
        </>
    )
}

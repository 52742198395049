import React from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

export default function Part3() {
    return (
        <React.Fragment>
            <Box my={1}>
                <Box my={2}>
                    <Typography className="text-bold">3. การนำข้อมูลส่วนบุคคลไปใช้</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;เมื่อได้รับความยินยอมจากท่าน หรือเมื่อมีฐานทางกฎหมายสำหรับการใช้ข้อมูลส่วนบุคคลของท่าน (แล้วแต่กรณี) ซึ่งรวมถึง แต่ไม่จำกัดเพียงฐานการปฏิบัติตามสัญญา วัตถุประสงค์ทางสถิติ วัตถุประสงค์เพื่อประโยชน์อันชอบด้วยกฎหมาย และเพื่อปฏิบัติหน้าที่ตามกฎหมาย บริษัทจะใช้ข้อมูลส่วนบุคคลของท่านตามรายละเอียดในตารางด้านล่างนี้</Typography>
                </Box>
                <Box my={2}>
                    <Typography className="text-bold">3.1 สำหรับผู้หางาน</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;ตารางด้านล่างอธิบายวัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลของผู้หางานแต่ละประเภท</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>วัตถุประสงค์ในการประมวลผล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ประเภทข้อมูลส่วนบุคคลของผู้หางานที่ใช้การประมวลผล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อยืนยันและระบุตัวตนก่อนเข้าสู่ระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>บัญชีผู้ใช้งาน (อีเมลและรหัสผ่าน)</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อการให้บริการค้นหางานและเป็นตัวกลางในการสมัครงานสำหรับผู้หางาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>
                                        ใบสมัครงานที่ผู้หางานได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด จาก 4 วิธีการสมัครงานดังนี้
                                        <ul>
                                            <li className='text-normal'>สมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                            <li className='text-normal'>สมัครด้วยการส่งไฟล์ประวัติ</li>
                                            <li className='text-normal'>สมัครด้วยการส่งอีเมล</li>
                                            <li className='text-normal'>สมัครด้วยการกรอกประวัติแบบย่อ</li>
                                        </ul>
                                    </li>
                                    <li className='text-normal'>ประวัติการสมัครงาน</li>
                                    <li className='text-normal'>งานที่สนใจ</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อพัฒนาและปรับปรุงการบริการโดยการตรวจสอบประวัติและใบสมัครของผู้หางาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>
                                        ใบสมัครงานที่ผู้หางานได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด จาก 4 วิธีการสมัครงานดังนี้
                                        <ul>
                                            <li className='text-normal'>สมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                            <li className='text-normal'>สมัครด้วยการส่งไฟล์ประวัติ</li>
                                            <li className='text-normal'>สมัครด้วยการส่งอีเมล</li>
                                            <li className='text-normal'>สมัครด้วยการกรอกประวัติแบบย่อ</li>
                                        </ul>
                                    </li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อพัฒนาและปรับปรุงการบริการโดยการคัดกรองอีเมลสแปม และอีเมลโฆษณาต่าง ๆ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ใบสมัครจากวิธีการส่งอีเมล</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อใช้เป็นข้อมูลสำหรับตรวจสอบและบันทึกการผิดนัดสัมภาษณ์ หรือการกระทำที่ไม่เหมาะสมอื่น ๆ ของผู้หางาน จากผู้ประกอบการ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>
                                        ใบสมัครงานที่ผู้หางานได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด จาก 4 วิธีการสมัครงานดังนี้
                                        <ul>
                                            <li className='text-normal'>สมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                            <li className='text-normal'>สมัครด้วยการส่งไฟล์ประวัติ</li>
                                            <li className='text-normal'>สมัครด้วยการส่งอีเมล</li>
                                            <li className='text-normal'>สมัครด้วยการกรอกประวัติแบบย่อ</li>
                                        </ul>
                                    </li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อวิจัยและพัฒนาการให้บริการในรูปแบบใหม่</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>
                                        ใบสมัครงานที่ผู้หางานได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด จาก 4 วิธีการสมัครงานดังนี้
                                        <ul>
                                            <li className='text-normal'>สมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                            <li className='text-normal'>สมัครด้วยการส่งไฟล์ประวัติ</li>
                                            <li className='text-normal'>สมัครด้วยการส่งอีเมล</li>
                                            <li className='text-normal'>สมัครด้วยการกรอกประวัติแบบย่อ</li>
                                        </ul>
                                    </li>
                                    <li className='text-normal'>ประวัติการสมัครงาน</li>
                                    <li className='text-normal'>งานที่สนใจ</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อพัฒนาและปรับปรุงการค้นหางานให้ตอบโจทย์ความต้องการของผู้หางาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>การค้นหาตำแหน่งงาน</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อวิเคราะห์ ออกแบบและพัฒนาเว็บไซต์ และ/หรือ แอปพลิเคชัน บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ข้อมูลการใช้งานเว็บไซต์ และ/หรือ แอปพลิเคชัน บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อประชาสัมพันธ์ข่าวสารและตำแหน่งงานใหม่ให้ตรงกับคุณสมบัติของผู้หางาน</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>ประวัติการสมัครงาน</li>
                                    <li className='text-normal'>งานที่สนใจ</li>
                                    <li className='text-normal'>การค้นหาตำแหน่งงาน</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อจัดทำสถิติที่ไม่ระบุตัวตนเพื่อวัตถุประสงค์ทางการตลาด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ข้อมูลการใช้งานเว็บไซต์ และ/หรือ แอปพลิเคชัน บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>ประวัติการสมัครงาน</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อติดต่อสอบถามและแนะนำข้อมูลเพิ่มเติมเกี่ยวกับการให้บริการ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                    <li className='text-normal'>
                                        ใบสมัครงานที่ผู้หางานได้ส่งให้แก่บริษัท หรือถูกบันทึกและจัดเก็บในระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด จาก 4 วิธีการสมัครงานดังนี้
                                        <ul>
                                            <li className='text-normal'>สมัครด้วยประวัติ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                            <li className='text-normal'>สมัครด้วยการส่งไฟล์ประวัติ</li>
                                            <li className='text-normal'>สมัครด้วยการส่งอีเมล</li>
                                            <li className='text-normal'>สมัครด้วยการกรอกประวัติแบบย่อ</li>
                                        </ul>
                                    </li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อพัฒนาและปรับปรุงคุณภาพการให้บริการ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>เสียงสนทนาระหว่างผู้หางานกับ Call Center</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-bold">3.2 สำหรับผู้ประกอบการ</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;ตารางด้านล่างอธิบายวัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลของผู้ประกอบการแต่ละประเภท</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>วัตถุประสงค์ในการประมวลผล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ประเภทข้อมูลส่วนบุคคลของผู้หางานที่ใช้การประมวลผล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อยืนยันและระบุตัวตนก่อนเข้าสู่ระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>บัญชีผู้ใช้งาน (อีเมลและรหัสผ่าน)</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อให้บริการและเป็นตัวกลางในการรับสมัครงานสำหรับผู้ประกอบการ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>บัญชีผู้ใช้งาน (อีเมลและรหัสผ่าน)</li>
                                    <li className='text-normal'>ข้อมูลติดต่อของผู้ประกอบการเพื่อการรับสมัครงาน</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อติดต่อสอบถามและแนะนำข้อมูลเพิ่มเติมเกี่ยวกับการให้บริการ การนำเสนอบริการใหม่ หรือประชาสัมพันธ์ข่าวสารการให้บริการของบริษัท</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ข้อมูลติดต่อของผู้ประกอบการเพื่อการรับสมัครงาน</li>
                                    <li className='text-normal'>ข้อมูลติดต่อของผู้ประกอบการสำหรับการใช้บริการบนระบบ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อลงโฆษณาประกาศรับสมัครงาน หรือโฆษณาในรูปแบบอื่น</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>ข้อมูลประกอบการลงโฆษณา</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อพัฒนาและปรับปรุงคุณภาพการให้บริการ</TableCell>
                            <TableCell className='text-normal tableprivacy'>
                                <ul>
                                    <li className='text-normal'>เสียงสนทนาระหว่างผู้ประกอบการกับ Call Center</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-bold">3.3 สำหรับผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</Typography>
                    <Typography className="text-normal">&nbsp;&nbsp;ตารางด้านล่างอธิบายวัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลของผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</Typography>
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>วัตถุประสงค์ในการประมวลผล</TableCell>
                            <TableCell className='text-normal tableprivacy'>ประเภทข้อมูลส่วนบุคคลของผู้หางานที่ใช้การประมวลผล</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='text-normal tableprivacy'>เพื่อประชาสัมพันธ์ข่าวสาร บทความแนะนำ หรือข้อมูลที่เป็นประโยชน์อื่น ๆ ของ บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด</TableCell>
                            <TableCell className='text-normal tableprivacy'>อีเมลของผู้ติดตาม blog.บริษัท ยูนิเวิร์ส ออฟ อีโวลูชั่น 4289 จำกัด.com</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box my={2}>
                    <Typography className="text-normal">ทั้งนี้ ท่านรับทราบและยอมรับว่า ในระหว่างการติดต่อสื่อสารกับบริษัทหรือทีมงานของบริษัท บริษัทอาจบันทึกเสียงสนทนาของท่านหรือบันทึกรายละเอียดการติดต่อด้วยวิธีการใด ๆ</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

import React from 'react'
import { NavLink as RouterLink } from "react-router-dom";
// import PropTypes from 'prop-types';
// @mui
import {
    Stack,
    ListItemText,
} from "@mui/material";
import { StyledNavItem } from './styles';

export default function TabLink() {
    return (
        <Stack
            sx={{
                flexGrow: 1,
                display: { xs: "none", md: "none", lg: "flex" },
            }}
            direction="row"
            display="flex"
            justifyContent="center"
            spacing={{
                xs: 0.5,
                sm: 1,
            }}
        >
            {/* <StyledNavItem
                component={RouterLink}
                to="/cookie"
                sx={{
                    fontSize: "16px",
                    '&.active': {
                        color: '#c60c0c',
                        bgcolor: 'action.selected',
                        fontWeight: 600,
                    },
                }}
            >
                <ListItemText sx={{ paddingLeft: 3 }} disableTypography>นโยบายคุกกี้</ListItemText>
            </StyledNavItem> */}
            <StyledNavItem
                component={RouterLink}
                to="/term_service_job"
                sx={{
                    fontSize: "16px",
                    '&.active': {
                        color: '#c60c0c',
                        bgcolor: 'action.selected',
                        fontWeight: 600,
                    },
                }}
            >
                <ListItemText sx={{ paddingLeft: 3 }} disableTypography>เงื่อนไขข้อตกลงการใช้บริการสำหรับผู้ใช้</ListItemText>
            </StyledNavItem>
            <StyledNavItem
                component={RouterLink}
                to="/term_service_seeker"
                sx={{
                    fontSize: "16px",
                    '&.active': {
                        color: '#c60c0c',
                        bgcolor: 'action.selected',
                        fontWeight: 600,
                    },
                }}
            >
                <ListItemText sx={{ paddingLeft: 3 }} disableTypography>เงื่อนไขข้อตกลงการใช้บริการสำหรับผู้ประกอบ</ListItemText>
            </StyledNavItem>
            <StyledNavItem
                component={RouterLink}
                to="/privacy"
                sx={{
                    fontSize: "16px",
                    '&.active': {
                        color: '#c60c0c',
                        bgcolor: 'action.selected',
                        fontWeight: 600,
                    },
                }}
            >
                <ListItemText sx={{ paddingLeft: 3 }} disableTypography>นโยบายความเป็นส่วนตัว</ListItemText>
            </StyledNavItem>
        </Stack>
    )
}

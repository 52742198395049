import React from "react";
import {
    TableRow,
    TableCell,
    Box,
} from "@mui/material";
import moment from 'moment';
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";

export default function WorkRow(props) {

    const { row, handlePageNow, index } = props

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 721px)",
    });
    const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

    return (
        <>
            {isDesktopOrLaptop && (
                <>
                    <TableRow className="tablerow" tabIndex={-1} key={row.id} hover id="viewwork" onClick={(e) => handlePageNow(e, row.id)} sx={{ cursor: 'pointer' }}>
                        <TableCell align="left" width="5%" id="viewwork">
                            {index + 1}
                        </TableCell>

                        <TableCell label="Name" align="left" width="15%" id="viewwork">
                            {row.positionW}
                        </TableCell>

                        <TableCell align="left" width="12%" id="viewwork">
                            {row.companyName}
                        </TableCell>

                        <TableCell align="left" width="12%" id="viewwork">
                            {row.province}
                        </TableCell>

                        <TableCell align="left" width="12%" id="viewwork">
                            {row.rate_em + ' คน'}
                        </TableCell>

                        <TableCell align="left" width="12%" id="viewwork">
                            <Box className="text">
                                {moment(row.date, "MM/DD/YYYY : HH:mm:ss a", i18next.language).fromNow()}
                            </Box>
                        </TableCell>
                    </TableRow>
                </>
            )}
            {isMobile && (
                <>
                    <TableRow
                        tabIndex={-1}
                        hover onClick={(e) => handlePageNow(e, row.id)} sx={{ cursor: 'pointer' }}
                        key={row.id}
                    >
                        <TableCell label="Name" hover="true" align="left" id="viewwork">
                            {row.positionW}
                        </TableCell>

                        <TableCell label="Username" align="left" id="viewwork">
                            {row.companyName}
                        </TableCell>

                        <TableCell label="Username" align="left" id="viewwork">
                            {row.rate_em}
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    )
}

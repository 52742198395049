import React from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function Why() {
    return (
        <Container>
            <Stack my={3}>
                <Stack my={2}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Box>
                                <Box component="img" src='/assets/images/uoe4289/about.svg' alt='why pic' width="100%" height="100%" />
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography variant='h2' fontSize="28px" fontWeight={600}><Trans i18nKey="whytitleabout" /></Typography>
                            <Box mt={3}>
                                <Typography variant='body1'><Trans i18nKey="whydetailabout" /></Typography>
                            </Box>
                            <Box mt={3}>
                                <Typography variant='h2' fontSize="24px" fontWeight={600}><Trans i18nKey="whysubtitleabout" /></Typography>
                                <Box component="ul">
                                    <Box component="li"><Trans i18nKey="whysubdetail1about" /></Box>
                                    <Box component="li"><Trans i18nKey="whysubdetail2about" /></Box>
                                    <Box component="li"><Trans i18nKey="whysubdetail3about" /></Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            <Divider />
            <Stack my={3}>
                <Stack my={2}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} xs={12} sm={12} md={6}>
                            <Grid xs={12} sm={12} md={6}>
                                <Box component="img" src='/assets/images/uoe4289/about_01.svg' alt='รูปภาพหาย' width="100%" height="100%" />
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Box component="img" src='/assets/images/uoe4289/about_02.svg' alt='รูปภาพหาย' width="100%" height="100%" />
                            </Grid>
                            <Box>
                                <Typography variant='body1' color="black" fontWeight={600}><Trans i18nKey="whytitle1about" /></Typography>
                                <Typography><Trans i18nKey="whydetail1about" /></Typography>
                            </Box>
                        </Grid>

                        <Grid container spacing={2} xs={12} sm={12} md={6}>
                            <Grid xs={12} sm={12} md={6}>
                                <Box component="img" src='/assets/images/uoe4289/about_03.svg' alt='รูปภาพหาย' width="100%" height="100%" />
                            </Grid>
                            <Grid xs={12} sm={12} md={6}>
                                <Box component="img" src='/assets/images/uoe4289/about_04.svg' alt='รูปภาพหาย' width="100%" height="100%" />
                            </Grid>
                            <Box>
                                <Typography variant='body1' color="black" fontWeight={600}><Trans i18nKey="whytitle2about" /></Typography>
                                <Typography><Trans i18nKey="whydetail2about" /></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}

import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
import { Trans } from 'react-i18next';

export default function Ads() {
    return (
        <Stack>
            <Box sx={{ position: "relative", width: "100%", clear: "both", float: "left", overflow: "hidden" }}>
                <Stack mx={2} my={2}>
                    <Box sx={{ top: "auto", opacity: 1, bottom: 0, left: 0, width: "100%", zIndex: 0 }}>
                        <Box component="div" className="overlay" sx={{ opacity: 0.8, backgroundColor: "#000000" }}></Box>
                        <Box sx={{ backgroundImage: `url('/assets/images/uoe4289/about_01.svg')`, backgroundRepeat: "repeat-x", backgroundPosition: "center center", position: "absolute", width: "100%", height: "100%", bottom: 0, left: 0 }}></Box>
                    </Box>
                    <Box sx={{ position: "relative", width: "100%" }}>
                        <Box className='container'>
                            <Box sx={{ border: "none", marginLeft: "auto", paddingLeft: 0, marginRight: 0, zoom: 1, }} color="white">
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='h1' fontWeight={300} fontSize="34px" textAlign="center"><Trans i18nKey="adstitlehome" /></Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='body1' fontSize="18px" textAlign="center"><Trans i18nKey="adsdetailhome" /></Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button color='error' variant="contained" startIcon={<Iconify icon="material-symbols:edit" />}><Trans i18nKey="adsbuttonhome" /></Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}

import React from 'react'
import { Box, Container, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function ContactTitle() {
    return (
        <Stack>
            <Box sx={{ position: "relative", width: "100%", clear: "both", float: "left", overflow: "hidden" }}>
                <Stack mb={2}>
                    <Box sx={{ top: "auto", opacity: 1, bottom: 0, left: 0, width: "100%", zIndex: 0 }}>
                        <Box component="div" className="overlay" sx={{ opacity: 0.8, backgroundColor: "#000000" }}></Box>
                        <Box sx={{ backgroundImage: `url('/assets/images/uoe4289/slider_header.svg')`, backgroundRepeat: "repeat-x", backgroundPosition: "center center", position: "absolute", width: "100%", height: "100%", bottom: 0, left: 0 }}></Box>
                    </Box>
                    <Box sx={{ position: "relative", width: "100%" }}>
                        <Container>
                            <Box className='container' my={10}>
                                <Box sx={{ border: "none", marginLeft: "auto", paddingLeft: 0, marginRight: 0, zoom: 1, }} color="white">
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography variant='h1' fontWeight={600} fontSize="34px"><Trans i18nKey="titlecontact" /></Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography variant='body1' fontSize="16px" textAlign="center"><Trans i18nKey="subcontact" /></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}

import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Popover,
  MenuItem,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Iconify from "../../../comonents/iconify";
import moment from "moment";
import i18next from "i18next";

export default function WorkRow(props) {
  const { row, handlePageNow, index } = props;

  const [open, setOpen] = useState(null);
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 721px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <TableRow className="tablerow" tabIndex={-1} key={row.id}>
            <TableCell align="left" width="5%">
              <Box className="text">{index + 1}</Box>
            </TableCell>

            <TableCell label="Name" align="left" width="15%">
              <Box className="text">{row.positionW}</Box>
            </TableCell>

            <TableCell align="left" width="12%">
              <Box className="text">{row.companyName}</Box>
            </TableCell>

            <TableCell align="left" width="12%">
              <Box className="text">{row.province}</Box>
            </TableCell>

            <TableCell align="left" width="12%">
              <Box className="text">{row.rate_em + " คน"}</Box>
            </TableCell>

            <TableCell align="left" width="12%">
              <Box className="text">
                {moment(row.date, "MM/DD/YYYY", i18next.language).format("LL")}
              </Box>
            </TableCell>
            <TableCell width="7%" className="tdd" align="left" label="Action">
              <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 140,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <MenuItem
                  id="viewwork"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="viewwork"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"carbon:view-filled"}
                    sx={{ mr: 2 }}
                  />
                  View
                </MenuItem>

                <MenuItem
                  id="editwork"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="editwork"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"eva:edit-fill"}
                    sx={{ mr: 2 }}
                  />
                  Edit
                </MenuItem>

                <MenuItem
                  id="deletework"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="deletework"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"material-symbols:delete"}
                    sx={{ mr: 2 }}
                  />
                  Delete
                </MenuItem>
              </Popover>
            </TableCell>
          </TableRow>
        </>
      )}
      {isMobile && (
        <>
          <TableRow
            tabIndex={-1}
            sx={{ "& > *": { borderBottom: "unset" } }}
            key={row.id}
          >
            <TableCell label="Name" hover="true" align="left" width="10%">
              <Box className="text">{row.positionW}</Box>
            </TableCell>

            <TableCell label="Username" align="left" width="10%">
              <Box className="text">{row.companyName}</Box>
            </TableCell>

            <TableCell width="7%" className="tdd" align="left" label="Action">
              <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 140,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <MenuItem
                  id="viewwork"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="viewwork"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"carbon:view-filled"}
                    sx={{ mr: 2 }}
                  />
                  View
                </MenuItem>

                <MenuItem
                  id="editwork"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="editwork"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"eva:edit-fill"}
                    sx={{ mr: 2 }}
                  />
                  Edit
                </MenuItem>

                <MenuItem
                  id="deletework"
                  onClick={(e) => handlePageNow(e, row.id)}
                >
                  <Iconify
                    id="deletework"
                    onClick={(e) => handlePageNow(e, row.id)}
                    icon={"material-symbols:delete"}
                    sx={{ mr: 2 }}
                  />
                  Delete
                </MenuItem>
              </Popover>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import InputTextMasked from "../../utils/inputmask";
import { MuiFileInput } from "mui-file-input";
var thai = require("thai-data");

export default function Information(props) {
  const {
    inputWork,
    setInputWork,
    errorWork,
    setErrorWork,
    setImage,
    file,
    setFile,
  } = props;

  const handleInputWork = (e) => {
    setInputWork({ ...inputWork, [e.target.name]: e.target.value });
    setErrorWork({ ...errorWork, [e.target.name]: "" });
  };

  const handleLogoFile = (newFile) => {
    setFile(newFile);
    setImage(URL.createObjectURL(newFile));
    setInputWork({ ...inputWork, logo: newFile.name });
  };

  var getZipSub = thai.getAutoSuggestion(inputWork.zipcode);

  const handleChangeZipCode = (e) => {
    setInputWork({
      ...inputWork,
      zipcode: e.target.value,
      subdist: "",
      dist: "",
      province: "",
    });
    setErrorWork({ ...errorWork, [e.target.name]: "" });
  };
  const handleAddress = (e) => {
    var getAllData = thai.getAutoSuggestion(
      inputWork.zipcode,
      `${e.target.value}`
    );
    setInputWork({
      ...inputWork,
      subdist: `${e.target.value}`,
      dist: getAllData.districtName,
      province: getAllData.provinceName,
    });
    setErrorWork({ ...errorWork, [e.target.name]: "" });
  };

  return (
    <React.Fragment>
      <Box>
        <Container>
          <Grid container spacing={2} my={3}>
            <Grid xs={12} sm={4} md={4}>
              <Box>
                <TextField
                  required
                  name="companyName"
                  id="companyName"
                  label="ชื่อบริษัท"
                  placeholder="ชื่อบริษัท จำกัด"
                  value={inputWork.companyName}
                  onChange={handleInputWork}
                  sx={{ width: "100%" }}
                  helperText={
                    errorWork.companyName && (
                      <span className="errorText">{errorWork.companyName}</span>
                    )
                  }
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <Box>
                <MuiFileInput
                  name="logo"
                  inputProps={{ accept: "image/*" }}
                  placeholder="Insert a file Logo"
                  value={file}
                  onChange={handleLogoFile}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <FormControl fullWidth>
                <InputLabel>ประเภทบริษัท</InputLabel>
                <Select
                  label="ประเภทบริษัท"
                  name="companyType"
                  id="companyType"
                  value={inputWork.companyType}
                  onChange={handleInputWork}
                >
                  <MenuItem value="ออฟฟิศ">ออฟฟิศ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <Box>
                <TextField
                  type="email"
                  name="email"
                  id="email"
                  label="อีเมล"
                  placeholder="example@gmail.com"
                  value={inputWork.email}
                  onChange={handleInputWork}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <Box>
                <InputTextMasked
                  name="phone"
                  id="phone"
                  label="เบอร์โทรศัพท์"
                  placeholder="012-345-6789"
                  options={{ numericOnly: true, blocks: [10] }}
                  value={inputWork.phone}
                  onChange={handleInputWork}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <TextField
                sx={{ width: "100%" }}
                label="รายละเอียดที่อยู่บริษัท"
                name="detailCompany"
                id="detailCompany"
                multiline
                rows={2}
                value={inputWork.detailCompany}
                onChange={handleInputWork}
              />
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <TextField
                required
                value={inputWork.zipcode}
                style={{ width: "100%" }}
                onChange={handleChangeZipCode}
                name="zipcode"
                id="zipcode"
                label="รหัสไปรษณีย์"
                inputProps={{ maxLength: 5 }}
                helperText={
                  errorWork.zipcode && (
                    <span className="errorText">{errorWork.zipcode}</span>
                  )
                }
              />
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <InputLabel>ตำบล/แขวง*</InputLabel>
                <Select
                  required
                  value={inputWork.subdist}
                  name="subdist"
                  label="ตำบล/แขวง"
                  onChange={handleAddress}
                >
                  {(getZipSub.subDistrict === null) === true ? (
                    <MenuItem>ไม่มี</MenuItem>
                  ) : (
                    getZipSub.subDistrict.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
                <FormHelperText>
                  {errorWork.subdist && (
                    <span className="errorText">{errorWork.subdist}</span>
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <TextField
                name="dist"
                style={{ width: "100%" }}
                value={inputWork.dist}
                label="อำเภอ/เขต"
              />
            </Grid>

            <Grid xs={12} sm={3} md={3}>
              <TextField
                name="province"
                style={{ width: "100%" }}
                value={inputWork.province}
                label="จังหวัด"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}

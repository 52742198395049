import { Box, Container, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import InputTextMasked from '../../utils/inputmask'
export default function Position(props) {

  const { inputWork, setInputWork, errorWork, setErrorWork } = props

  const handleInputWork = (e) => {
    setInputWork({ ...inputWork, [e.target.name]: e.target.value })
    setErrorWork({ ...errorWork, [e.target.name]: '' })
  }
  return (
    <React.Fragment>
      <Box>
        <Container>
          <Grid container spacing={2} my={3}>
            <Grid xs={12} sm={4} md={4}>
              <Box>
                <TextField required name='departmentW' id='departmentW' label="แผนกงานที่ต้องการ" placeholder='แผนกงาน' sx={{ width: "100%" }} value={inputWork.departmentW} onChange={handleInputWork}
                  helperText={errorWork.departmentW && (<span className="errorText">{errorWork.departmentW}</span>)} />
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <Box>
                <TextField required name='positionW' id='positionW' label="ตำแหน่งงานที่ต้องการ" placeholder='ตำแหน่งงาน' sx={{ width: "100%" }} value={inputWork.positionW} onChange={handleInputWork}
                  helperText={errorWork.positionW && (<span className="errorText">{errorWork.positionW}</span>)} />
              </Box>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <Box>
                <InputTextMasked required name='rate_em' id='rate_em' label='อัตรารับสมัคร' placeholder='(กรอกเฉพาะตัวเลข)' options={{ numericOnly: true, }} value={inputWork.rate_em} sx={{ width: "100%" }} onChange={handleInputWork}
                  helperText={errorWork.rate_em && (<span className="errorText">{errorWork.rate_em}</span>)} />
              </Box>
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <InputLabel>ระดับการศึกษา</InputLabel>
                <Select name='education' id='education' value={inputWork.education} label="ระดับการศึกษา" onChange={handleInputWork}>
                  <MenuItem value="จบมัธยมปลาย">จบมัธยมปลาย</MenuItem>
                  <MenuItem value="จบปวช">จบปวช</MenuItem>
                  <MenuItem value="จบปวส">จบปวส</MenuItem>
                  <MenuItem value="จบปริญญาตรี">จบปริญญาตรี</MenuItem>
                  <MenuItem value="จบปริญญาโท">จบปริญญาโท</MenuItem>
                  <MenuItem value="จบปริญญาเอก">จบปริญญาเอก</MenuItem>
                  <MenuItem value="ไม่ระบุ">ไม่ระบุ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <Box>
                <TextField name='dateW' id='dateW' label='ช่วงวันทำงาน' placeholder='dd - dd' sx={{ width: "100%" }} value={inputWork.dateW} onChange={handleInputWork} />
              </Box>
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <Box>
                <TextField name='timeW' id='timeW' label='ช่วงเวลาทำงาน' placeholder='HH:mm - HH:mm' sx={{ width: "100%" }} value={inputWork.timeW} onChange={handleInputWork} />
              </Box>
            </Grid>

            <Grid xs={12} sm={3} md={3}>
              <Box>
                <TextField required name='salaryW' id='salaryW' label='เงินเดือน' placeholder='(กรอกเฉพาะตัวเลข)' sx={{ width: "100%" }} value={inputWork.salaryW} onChange={handleInputWork}
                  helperText={errorWork.salaryW && (<span className="errorText">{errorWork.salaryW}</span>)} />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <TextField
                name='detailPosition'
                id='detailPosition'
                sx={{ width: "100%" }}
                label="รายละเอียดตำแหน่งงาน"
                multiline
                rows={2}
                value={inputWork.detailPosition}
                onChange={handleInputWork}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  )
}